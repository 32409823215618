import { Container, Content, BlackButton, TransparentButton } from "./styles";
import { IconArrowRightGray } from "../../assets/icons/IconArrowRightGray";
import { IconFaq } from "../../assets/icons/IconFaq";
import { IconInfoCircle } from "../../assets/icons/IconInfoCircle";
import { IconReport } from "../../assets/icons/IconReport";
import { scrollToElement } from "../../utils/scrollToElement";
import { Link } from "react-router-dom";
import { addInteraction } from "../../utils/dataLayer";
import { addCTAEvent } from "../../utils/dataLayer";
import { IconPlayBlack } from "./../../assets/icons/IconPlayBlack";
import { IconBookmark } from "./../../assets/icons/IconBookmark";
import { IconProductBox } from "./../../assets/icons/IconProductBox";
import { IconFacebookGrayCircle } from "./../../assets/icons/IconFacebookGrayCircle";
import { IconInstagramGrayCircle } from "./../../assets/icons/IconInstagramGrayCircle";
import { IconLinkedinGrayCircle } from "./../../assets/icons/IconLinkedinGrayCircle";
import { contentsLink } from "../LpFooter";
import { IconDownload } from "../../assets/icons/IconDownload";

export const LpMenu = ({ setViewMenu, setShowVideo }) => {
  function handleClick(name) {
    const elements = {
      como_jogar: () => scrollToElement("#info"),
      conhecer_a_plataforma: () => scrollToElement("#platform"),
      perguntas: () => scrollToElement("#faq"),
    };
    setViewMenu();

    try {
      if (window.location.pathname !== "/") {
        setTimeout(() => elements[name](), 500);
      } else {
        elements[name]();
      }
    } catch { }
    addInteraction(`lp:header-links:${name}`);
  }

  function handleLoginButtonClick() {
    addCTAEvent();
    window.open("https://app.zedasorte.com.br/login");
  }
  function handleRegisterButtonClick() {
    addCTAEvent();
    window.open("https://app.zedasorte.com.br/registrar");
  }

  function handleClickConhecerPlataforma() {
    setViewMenu();
    setShowVideo(true);
  }

  return (
    <Container>
      <Content>
        <div className="optionsContainer">
          <div>
            <Link to="./quem-somos" className="link" onClick={setViewMenu}>
              <div>
                <IconReport />
                <p>quem somos</p>
                <IconArrowRightGray />
              </div>
            </Link>
          </div>
          <div>
            <Link to="/" className="link" onClick={() => handleClick("como_jogar")}>
              <div>
                <IconInfoCircle />
                <p>como jogar</p>
                <IconArrowRightGray />
              </div>
            </Link>
          </div>
          <div>
            <Link to="/" className="link" onClick={handleClickConhecerPlataforma}>
              <div>
                <IconPlayBlack />
                <p>conhecer a plataforma</p>
                <IconArrowRightGray />
              </div>
            </Link>
          </div>
          <div>
            <Link to={contentsLink} className="link" onClick={setViewMenu}>
              <div>
                <IconBookmark />
                <p>dicas do zé</p>
                <IconArrowRightGray />
              </div>
            </Link>
          </div>
          <div>
            <Link to={"/produtos/"} className="link" onClick={setViewMenu}>
              <div>
                <IconProductBox />
                <p>produtos</p>
                <IconArrowRightGray />
              </div>
            </Link>
          </div>
          <div>
            <Link to="./perguntas-frequentes" className="link" onClick={setViewMenu}>
              <div>
                <IconFaq />
                <p>perguntas frequentes</p>
                <IconArrowRightGray />
              </div>
            </Link>
          </div>
          <div>
            <Link to={"/baixar"} className="link" onClick={setViewMenu}>
              <div>
                <IconDownload />
                <p>baixar</p>
                <IconArrowRightGray />
              </div>
            </Link>
          </div>
        </div>
      </Content>
      <Content>
        <BlackButton onClick={handleLoginButtonClick}>acessar conta</BlackButton>
        <TransparentButton onClick={handleRegisterButtonClick}>criar conta</TransparentButton>
      </Content>
      <Content>
        <p>nossas redes:</p>
        <div className="socialmediasContainer">
          <a href="https://www.facebook.com/oficialzedasorte/" target="_blank" rel="noreferrer">
            <IconFacebookGrayCircle />
          </a>
          <a href="https://www.instagram.com/ozedasorteoficial/" target="_blank" rel="noreferrer">
            <IconInstagramGrayCircle />
          </a>
          <a href="https://www.linkedin.com/company/ozedasorte/" target="_blank" rel="noreferrer">
            <IconLinkedinGrayCircle />
          </a>
        </div>
      </Content>
    </Container>
  );
};
