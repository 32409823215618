import styled from "styled-components";

export const Container = styled.div`
    min-height: 100dvh;
    overflow: hidden;
    background: var(--color-white);
    position: fixed;
    padding-top: 85px;
    top: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    z-index: 2;

    @media screen and (min-width: 1105px) {
        display: none;
    }
`

export const Content = styled.div`
    width: 100vw;
    padding: 0 24px;

    header {
        padding: 0;
    }

    &:first-child {
        flex-grow: 3;
    }

    &:last-child {
        border-top: 1px solid var(--color-gray-300);
        padding: 16px 24px 0;

        p { 
            color: var(--color-gray-500);
            line-height: 20px;
            padding-bottom: 8px;
        }
    }

    div.optionsContainer {
        padding-top: 24px;
        div:not(:last-child) {
            margin-bottom: 4px;
        }

        a.link,
        div.link {
            div {
                display: flex;
                align-items: center;
                color: var(--color-black);
                padding: 16px;
                padding-right: 8px;
                /* margin-top: 16px; */
                background-color: var(--color-gray-100);
                border-radius: 12px;

                &:not(:last-child) {
                    border-bottom: 1px solid var(--color-gray-200);
                }

                p {
                    color: var(--color-black);
                    flex: 1;
                }

                svg:first-child {
                    margin-right: 16px;
                }
            }

            &:last-child div {
                border: none;
            }
        }
    }

    div.socialmediasContainer {
        margin-bottom: 16px;
        svg {
            margin-right: 16px;
        }
    }
`

export const BlackButton = styled.button`
    display: block;
    width: 100%;
    margin: 0 auto 4px;
    padding: 14px 16px;
    border: solid 1px var(--color-white);
    background: var(--color-black);
    border-radius: 65px;
    font-size: var(--text-size-xs);
    font-weight: 400;
    color: var(--color-white);
`

export const TransparentButton = styled.button`
    display: block;
    width: 100%;
    margin: 0 auto;
    padding: 14px 16px;
    border: solid 1.4px var(--color-black);
    background: transparent;
    border-radius: 65px;
    font-size: var(--text-size-xs);
    font-weight: 400;
`