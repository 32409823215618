export const IconTicketPurple = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.44444 3.25C3.57563 3.25 1.25 5.57563 1.25 8.44444C1.25 9.27618 1.72698 10.0342 2.47681 10.3941L2.96417 10.6281C4.1152 11.1806 4.1152 12.8194 2.96417 13.3719L2.47681 13.6059C1.72698 13.9658 1.25 14.7238 1.25 15.5556C1.25 18.4244 3.57563 20.75 6.44444 20.75H17.5556C20.4244 20.75 22.75 18.4244 22.75 15.5556C22.75 14.7238 22.273 13.9658 21.5232 13.6059L21.0358 13.3719C19.8848 12.8194 19.8848 11.1806 21.0358 10.6281L21.5232 10.3941C22.273 10.0342 22.75 9.27618 22.75 8.44444C22.75 5.57563 20.4244 3.25 17.5556 3.25H6.44444ZM2.75 8.44444C2.75 6.40406 4.40406 4.75 6.44444 4.75H17.5556C19.5959 4.75 21.25 6.40406 21.25 8.44444C21.25 8.69929 21.1038 8.93156 20.8741 9.04184L20.3867 9.27578C18.1012 10.3728 18.1012 13.6272 20.3867 14.7242L20.8741 14.9582C21.1038 15.0684 21.25 15.3007 21.25 15.5556C21.25 17.5959 19.5959 19.25 17.5556 19.25H6.44444C4.40406 19.25 2.75 17.5959 2.75 15.5556C2.75 15.3007 2.89615 15.0684 3.1259 14.9582L3.61327 14.7242C5.89884 13.6272 5.89883 10.3728 3.61327 9.27578L3.1259 9.04184C2.89615 8.93156 2.75 8.69929 2.75 8.44444Z" fill="#7D08FF" />
            <path d="M14.75 4.75C14.75 4.75 14.4337 4.75 14.0195 4.75C13.6053 4.75 13.25 4.75 13.25 4.75V5.06667C13.25 5.48088 13.5858 5.81667 14 5.81667C14.4142 5.81667 14.75 5.48088 14.75 5.06667V4.75Z" fill="#7D08FF" />
            <path d="M14.75 8.26667C14.75 7.85245 14.4142 7.51667 14 7.51667C13.5858 7.51667 13.25 7.85245 13.25 8.26667V10.4C13.25 10.8142 13.5858 11.15 14 11.15C14.4142 11.15 14.75 10.8142 14.75 10.4V8.26667Z" fill="#7D08FF" />
            <path d="M14.75 13.6C14.75 13.1858 14.4142 12.85 14 12.85C13.5858 12.85 13.25 13.1858 13.25 13.6V15.7333C13.25 16.1475 13.5858 16.4833 14 16.4833C14.4142 16.4833 14.75 16.1475 14.75 15.7333V13.6Z" fill="#7D08FF" />
            <path d="M14.75 18.9333C14.75 18.5191 14.4142 18.1833 14 18.1833C13.5858 18.1833 13.25 18.5191 13.25 18.9333V19.25H14H14.75V18.9333Z" fill="#7D08FF" />
        </svg>
    )
}