import { Link, useLocation } from "react-router-dom";
import { Breadcrumbs, Container, SeeMore, BlackButton, NotFound } from "./styles";
import { IconNavbarHomeActive } from "../../assets/icons/IconNavbarHomeActive";
import CompactProductCard from "../../components/CompactProductCard/index";
import { productsText } from "../../utils/productsText";

export default function ProductPage() {
  const location = useLocation();
  const currentProductId = location.pathname.split("/").pop();
  const dummyProduct = productsText.get(currentProductId);
  const keyValueProducts = Array.from(productsText.entries());
  const filteredProducts = keyValueProducts.filter((content) => content[0] !== currentProductId);

  return (
    <>
      {dummyProduct && (
        <Container>
          <Breadcrumbs>
            <Link to="/">
              <IconNavbarHomeActive />
            </Link>
            <span>/</span>
            <Link to={"/produtos/"}>produtos</Link>
            <span>/</span>
            <Link to={"/produtos/" + dummyProduct.link}>{dummyProduct.breadCrumb}</Link>
          </Breadcrumbs>
          {dummyProduct.contents}
        </Container>
      )}
      {!dummyProduct && <NotFound />}
      <SeeMore>
        <h1>Confira também...</h1>

        <div className="wrapper">
          <div>
            {filteredProducts.map((content) => (
              <CompactProductCard content={content[1]} key={content[0]} />
            ))}
          </div>
        </div>

        <Link to="/produtos">
          <BlackButton>conferir mais produtos</BlackButton>
        </Link>
      </SeeMore>
    </>
  );
}
