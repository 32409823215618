import styled from "styled-components";

export const Card = styled.div`
  padding: 24px;
  padding-bottom: 16px;
  border: 1px solid var(--color-gray-300);
  border-radius: 16px;
  min-width: 302px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:first-child {
    margin-left: 24px;
  }
  
  &:last-child {
    margin-right: 24px;
  }

  h2 {
    font-size: var(--text-size-xsm);
    line-height: 22px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .line {
    margin: 16px 0;
    width: 56px;
    border-top: solid 1px var(--color-gray-600);
  }

  p {
    color: var(--color-gray-500);
    font-size: var(--text-size-2xs);
    line-height: 18px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  a {
    display: inline-block;
    font-size: var(--text-size-2xs);
    line-height: 18px;
    padding: 15px 0;
    margin-top: 16px;
    color: var(--color-purple-600);

    &:hover {
      text-decoration: underline;
    }
  }

  @media screen and (min-width: 1105px) {
    min-width: 354px;
    padding: 42px;
    padding-bottom: 24px;

    h2 {
    font-size: var(--text-size-lg);
    line-height: 36px;
    }

    .line {
      margin: 24px 0;
    }

    p {
      font-size: var(--text-size-sm);
      line-height: 24px;
    }

    a {
      font-size: var(--text-size-sm);
      padding: 20px 0;
      margin-top: 24px;
    }
  }
`