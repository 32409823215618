import styled from "styled-components";

export const Container = styled.div`
    max-width: 1488px;
    padding: 0 24px;
    margin: 85px auto 64px;

    h1 {
    font-size: var(--text-size-md);
    line-height: 28px;
    margin-top: 40px;
}

@media screen and (min-width: 1105px) {
    margin-bottom: 144px;

    h1 {
        line-height: 52px;
        font-size: var(--text-size-xlg);
        margin-top: 80px;
    }
}
`

export const Breadcrumbs = styled.div`
    padding-top: 40px;
    display: flex;
    align-items: center;

    span {
        font-size: var(--text-size-3xs);
        margin: 0 8px;
        color: var(--color-gray-500);
    }

    a {
        font-size: var(--text-size-3xs);
        color: var(--color-gray-500);

        &:last-child {
            color: var(--color-black);
        }
    }
`

export const Products = styled.div`
    margin-top: 32px;
    display: grid;
    grid-auto-flow: row dense;
    gap: 8px;
    justify-content: center;


    @media screen and (min-width: 1105px) {
        margin-top: 48px;
        grid-template-columns: repeat(auto-fit, minmax(354px, 1fr));
    }
`

export const BlackButton = styled.button`
    display: block;
    margin: 48px auto 0;
    padding: 14px 16px;
    width: 100%;
    max-width: 328px;
    border: none;
    color: var(--color-white);
    background: var(--color-black);
    border-radius: 65px;
    font-size: var(--text-size-xs);
    font-weight: 400;

    &:hover {
        filter: brightness(1.1);
    }

    &.marginTop {
        margin-top: 48px;
    }

    &.marginBottom {
        margin-bottom: 48px;
    }

    @media screen and (min-width: 1105px) {
        font-size: var(--text-size-sm);
        line-height: 24px;
        padding: 20px 46px;
    }
`