import { Container } from "./styles";
import videoSource from "../../assets/videos/teaser.mp4";
import videoPoster from "../../assets/images/teaser-poster.png";

export const LpVideo = ({ setView }) => {
  return (
    <Container>
      <div className="videoContent">
        {/* <iframe src="https://www.youtube.com/embed/9qQioFljffA" title="Zé da Sorte" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen /> */}
        <video poster={videoPoster} controls autoPlay>
          <source src={videoSource} type="video/mp4" />
        </video>
      </div>
      <div className="background" onClick={() => setView(false)} />
    </Container>
  );
};
