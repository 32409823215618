import { Link, useLocation } from "react-router-dom";
import { Breadcrumbs, Container, SeeMore, BlackButton, NotFound } from "./styles";
import { IconNavbarHomeActive } from "../../assets/icons/IconNavbarHomeActive";
import CompactContentCard from "./../../components/CompactContentCard/index";
import { contentsLink } from "../../components/LpFooter";
import { contentsText } from "../../utils/contentsText";

export default function ContentPage() {
  const location = useLocation();
  const currentContentId = location.pathname.split("/").pop();
  const dummyContent = contentsText.get(currentContentId);
  const keyValueContents = Array.from(contentsText.entries());
  const filteredContents = keyValueContents.filter((content) => content[0] !== currentContentId);

  // TODO: Arrumar o css disso aki
  // if (!dummyContent) {
  //   return (<><h1>
  //     Conteúdo não encontrado
  //     </h1></>)
  // }

  return (
    <>
      {dummyContent && (
        <Container>
          <Breadcrumbs>
            <Link to="/">
              <IconNavbarHomeActive />
            </Link>
            <span>/</span>
            <Link to={contentsLink}>dicas do zé</Link>
            <span>/</span>
            <Link to={contentsLink + dummyContent.link}>{dummyContent.breadCrumb}</Link>
          </Breadcrumbs>
          {dummyContent.contents}
        </Container>
      )}
      {!dummyContent && <NotFound />}
      <SeeMore>
        <h1>Confira também...</h1>

        <div className="wrapper">
          <div>
            {filteredContents.map((content) => (
              <CompactContentCard content={content[1]} key={content[0]} />
            ))}
          </div>
        </div>

        <Link to="./dicas-do-ze">
          <BlackButton>conferir mais conteúdos</BlackButton>
        </Link>
      </SeeMore>
    </>
  );
}
