import { Close, Container, Content } from "./styles"
import { IconCloseWhite } from "../../../assets/icons/IconCloseWhite";
import imageCouponDesktop from "../../../assets/images/banner-coupon-desktop.png";
import imageCouponMobile from "../../../assets/images/banner-coupon-mobile.png";

export const Coupon = ({ setView }) => {

    function useCoupon() {
        navigator.clipboard.writeText("PRIMEIROZE");
        window.location.href = "https://app.zedasorte.com.br"
    }

    return (
        <Container>
            <Close>
                <button type="button" onClick={setView}>
                    <IconCloseWhite />
                </button>
            </Close>
            <Content>
                <div>
                    <picture>
                        <source srcSet={imageCouponMobile} media="(max-width: 999px)" />
                        <img src={imageCouponDesktop} alt="" />
                    </picture>
                    <button type="button" onClick={useCoupon}>usar cupom</button>
                    <p>oferta limitada, válida apenas para o primeiro jogo e restrita a um uso por CPF.</p>
                </div>
            </Content>
        </Container>
    )
}