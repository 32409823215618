import {
  GreenButton,
} from "./styles";

import { addCTAEvent } from "./../utils/dataLayer";

function handleButtonClick() {
  addCTAEvent();
  window.open("https://app.zedasorte.com.br");
}

export const productsText = new Map([
  [
    "produto-mega-sena",
    {
      title: "Mega-Sena com o Zé da Sorte",
      subtitle: "Sua melhor chance de concorrer ao grande prêmio!",
      breadCrumb: "mega-sena",
      link: "produto-mega-sena",
      description:
        "A Mega-Sena é a loteria mais famosa do Brasil, conhecida por seus prêmios milionários que podem transformar vidas! E com o Zé da Sorte, sua participação fica ainda mais simples e segura, porque cuidamos de toda a intermediação para você.",
      contents: (
        <>
          <h1>Mega-Sena com o Zé da Sorte</h1>
          <p>
            A Mega-Sena é a loteria mais famosa do Brasil, conhecida por seus prêmios milionários
            que podem transformar vidas! E com o Zé da Sorte, sua participação fica ainda mais
            simples e segura, porque cuidamos de toda a intermediação para você.
          </p>
          <h2>Veja como é simples jogar pelo Zé:</h2>
          <ol>
            <li>Você cadastra seus dados e valida sua conta;</li>
            <li>Escolhe seu jogo e os números da sorte (de 6 a 15);</li>
            <li>Faz o pagamento e espera o comprovante de registro do jogo para torcer;</li>
            <li>Seu jogo é registrado e o prêmio transferido em até 5 dias úteis.</li>
          </ol>
          <GreenButton onClick={ handleButtonClick }>
            JOGAR NA MEGA-SENA
          </GreenButton>
          <h2>Por que usar o Zé da Sorte?</h2>
          <ul>
            <li><strong>Praticidade:</strong> Jogue online sem precisar ir na lotérica.</li>
            <li><strong>Comodidade:</strong> Aposte de onde estiver e quando quiser.</li>
            <li><strong>Rapidez:</strong> Escolha o jogo, as dezenas, como pagar, e pronto!</li>
            <li><strong>Segurança:</strong> Jogos conferidos e registrados em loterias oficiais da Caixa.</li>
          </ul>
          <GreenButton onClick={ handleButtonClick } className="marginTop">
            APOSTAR AGORA
          </GreenButton>
          <h2>Dicas para aumentar suas chances</h2>
          <ul>
            <li><strong>Aposte mais números:</strong> Com mais números, suas chances de ganhar aumentam. Explore nossas opções para apostas maiores;</li>
            <li><strong>Mantenha-se atualizado:</strong> Acompanhe nossos emails para não perder nenhuma novidade ou promoção especial;</li>
          </ul>
          <p>
            Participar da Mega-Sena com o Zé da Sorte não é apenas sobre tentar a sorte. É sobre ter uma experiência sem complicações e com total segurança. Imagine a emoção de ver seus números serem sorteados e a nossa equipe cuidando de todo o processo para você!
          </p>
          <p>
            Comece agora e boa sorte! Não perca tempo e faça sua aposta na Mega-Sena com o Zé da Sorte hoje mesmo! Clique abaixo, escolha seus números e deixe que fazemos o resto.
          </p>
          <GreenButton onClick={ handleButtonClick } className="marginTop">
            JOGAR NA MEGA
          </GreenButton>
        </>
      ),
    },
  ],
  [
    "produto-dupla-sena",
    {
      title: "Dupla Sena: dobre suas chances de ganhar!",
      breadCrumb: "dupla-sena",
      link: "produto-dupla-sena",
      description:
        "A Dupla Sena é uma das loterias mais emocionantes do Brasil, oferecendo duas chances de ganhar em um único bilhete! As duas rodadas de sorteio aumentam significativamente suas oportunidades de conquistar um prêmio incrível.",
      contents: (
        <>
          <h1>Dupla Sena: dobre suas chances de ganhar!</h1>
          <p>
            A Dupla Sena é uma das loterias mais emocionantes do Brasil, oferecendo duas chances de ganhar em um único bilhete! As duas rodadas de sorteio aumentam significativamente suas oportunidades de conquistar um prêmio incrível.
          </p>
          <h2>Como Funciona com o Zé da Sorte?</h2>
          <ol>
            <li>Você cadastra seus dados e valida sua conta;</li>
            <li>Escolhe seu jogo e os números da sorte (de 6 a 15);</li>
            <li>Faz o pagamento e espera o comprovante de registro do jogo para torcer;</li>
            <li>Seu jogo é registrado e o prêmio transferido em até 5 dias úteis.</li>
          </ol>
          <GreenButton onClick={ handleButtonClick }>
            QUERO 2 CHANCES DE GANHAR
          </GreenButton>
          <h2>Por que usar o Zé da Sorte?</h2>
          <ul>
            <li><strong>Praticidade:</strong> Jogue online sem precisar ir na lotérica.</li>
            <li><strong>Comodidade:</strong> Aposte de onde estiver e quando quiser.</li>
            <li><strong>Rapidez:</strong> Escolha o jogo, as dezenas, como pagar, e pronto!</li>
            <li><strong>Segurança:</strong> Jogos conferidos e registrados em loterias oficiais da Caixa.</li>
          </ul>
          <GreenButton onClick={ handleButtonClick } className="marginTop">
            JOGAR NA DUPLA SENA
          </GreenButton>
          <h2>Dicas para potencializar suas chances de ganhar</h2>
          <ul>
            <li><strong>Aposte mais números:</strong> Escolher mais números aumenta suas chances de ganhar. Explore nossas opções para apostas maiores e mais impactantes;</li>
            <li><strong>Atenção aos sorteios:</strong> Acompanhe nossos comunicados para não perder nenhuma novidade ou oportunidade especial.</li>
          </ul>
          <GreenButton onClick={ handleButtonClick } className="marginTop marginBottom">
            JOGAR NA DUPLA SENA
          </GreenButton>
          <p>
            Experimente a emoção de ter duas chances em cada sorteio, com a garantia de uma experiência sem complicações. Imagine a possibilidade de ganhar prêmios significativos enquanto nós cuidamos de todos os detalhes para você!
          </p>
          <p>
            Não perca a oportunidade de transformar sua sorte com a Dupla Sena! Clique abaixo para fazer sua aposta com o Zé da Sorte e prepare-se para dobrar suas chances de ganhar!
          </p>
          <p>
            Bora fazer aquela fezinha no Zé!
          </p>
          <GreenButton onClick={ handleButtonClick } className="marginTop">
            BORA DE FEZINHA NO ZÉ
          </GreenButton>
        </>
      ),
    },
  ],
  [
    "produto-quina",
    {
      title:
        "Quina: uma das loterias mais tradicionais do Brasil!",
      breadCrumb: "quina",
      link: "produto-quina",
      description:
        "A Quina é uma das loterias mais tradicionais e emocionantes do Brasil, conhecida por oferecer prêmios substanciais e frequentes. Além de ser famosa por suas chances de ganhar e pela simplicidade do jogo.",
      contents: (
        <>
          <h1>Quina: uma das loterias mais tradicionais do Brasil!</h1>
          <p>
            A Quina é uma das loterias mais tradicionais e emocionantes do Brasil, conhecida por oferecer prêmios substanciais e frequentes. Além de ser famosa por suas chances de ganhar e pela simplicidade do jogo.
          </p>
          <p>
            Com o Zé da Sorte você joga na Quina com toda a facilidade e segurança!
          </p>
          <GreenButton onClick={ handleButtonClick } className="marginTop">
            JOGAR NA QUINA
          </GreenButton>
          <h2>Como jogar na Quina?</h2>
          <ul>
            <li><strong>Escolha seus números:</strong> Selecione de 5 a 15 números entre 01 e 80 diretamente na nossa plataforma, com a ajuda de uma interface intuitiva;</li>
            <li><strong>Realize sua aposta:</strong> Faça sua aposta com total segurança e sem complicações. Nós garantimos que sua participação seja registrada corretamente em uma lotérica oficial;</li>
            <li><strong>Acompanhe os sorteios:</strong> Informamos a você sobre os resultados dos sorteios e sobre qualquer prêmio que você possa ganhar.</li>
          </ul>
          <GreenButton onClick={ handleButtonClick } className="marginTop">
            APOSTAR AGORA
          </GreenButton>
          <h2>Por que usar o Zé da Sorte?</h2>
          <ul>
            <li><strong>Praticidade:</strong> Jogue online sem precisar ir na lotérica.</li>
            <li><strong>Comodidade:</strong> Aposte de onde estiver e quando quiser.</li>
            <li><strong>Rapidez:</strong> Escolha o jogo, as dezenas, como pagar, e pronto!</li>
            <li><strong>Segurança:</strong> Jogos conferidos e registrados em loterias oficiais da Caixa.</li>
          </ul>
          <p>
            Mude sua vida com a Quina! Faça sua aposta com o Zé da Sorte e prepare-se para conquistar prêmios incríveis!
          </p>
          <GreenButton onClick={ handleButtonClick } className="marginTop">
            BORA JOGAR
          </GreenButton>
        </>
      ),
    },
  ],
  [
    "produto-loto-facil",
    {
      title: "Lotofácil: probabilidades atrativas e sorteios frequentes",
      breadCrumb: "loto-facil",
      link: "produto-loto-facil",
      description:
        "Lotofácil com o Zé da Sorte E com o Zé da Sorte, participar ficou ainda mais fácil e seguro! Nós cuidamos de todo o processo para que você possa focar apenas em torcer pelos seus números da sorte.",
      contents: (
        <>
          <h1>Lotofácil com o Zé da Sorte</h1>
          <p>
            E com o Zé da Sorte, participar ficou ainda mais fácil e seguro! Nós cuidamos de todo o
            processo para que você possa focar apenas em torcer pelos seus números da sorte.
          </p>
          <h2>Veja como é fácil jogar na Lotofácil com o Zé:</h2>
          <ol>
            <li>Cadastre-se no site e valide sua conta;</li>
            <li>Escolha seus números da sorte (de 15 a 20);</li>
            <li>Realize o pagamento e aguarde a confirmação do seu jogo;</li>
            <li>Seu jogo é registrado e, em caso de vitória, o prêmio é transferido para sua conta em até 5 dias úteis.</li>
          </ol>
          <GreenButton onClick={ handleButtonClick }>
            JOGAR NA LOTOFÁCIL
          </GreenButton>
          <h2>Por que apostar com o Zé da Sorte?</h2>
          <ul>
            <li><strong>Praticidade:</strong> Jogue online sem precisar sair de casa ou enfrentar filas.</li>
            <li><strong>Comodidade:</strong> Aposte de onde estiver e a qualquer hora do dia.</li>
            <li><strong>Agilidade:</strong> Selecione seus números, faça o pagamento e pronto!</li>
            <li><strong>Segurança:</strong> Suas apostas são registradas e conferidas diretamente nas loterias oficiais da Caixa.</li>
          </ul>
          <GreenButton onClick={ handleButtonClick } className="marginTop">
            APOSTAR AGORA
          </GreenButton>
          <h2>Dicas para aumentar suas chances</h2>
          <ul>
            <li><strong>Aposte mais números:</strong> Na Lotofácil, você pode escolher até 20 números, aumentando consideravelmente suas chances de ganhar;</li>
            <li><strong>Participe de bolões:</strong> Junte-se a outros apostadores e multiplique suas chances de forma acessível;</li>
            <li><strong>Fique de olho nas promoções:</strong> Acompanhe nossas ofertas e promoções exclusivas por email e aumente suas chances.</li>
          </ul>
          <p>
            Com o Zé da Sorte, apostar na Lotofácil é muito mais do que apenas jogar. É ter a tranquilidade de uma experiência segura, sem complicações, e com suporte total em todas as etapas. Imagine a alegria de ver seus números sorteados enquanto nós cuidamos de tudo para você!
          </p>
          <p>
            Não perca tempo! Faça sua aposta agora mesmo na Lotofácil com o Zé da Sorte e boa sorte! Clique abaixo para escolher seus números e deixe o resto conosco.
          </p>
          <GreenButton onClick={ handleButtonClick } className="marginTop">
            JOGAR NA LOTOFÁCIL
          </GreenButton>
        </>
      ),
    },
  ],
]);
