import { IconPlusBlack } from "../../assets/icons/IconPlusBlack";
import { IconMinusBlack } from "../../assets/icons/IconMinusBlack";
import { Container } from "./styles";
import { useState } from "react";

export const LpFaq = ({ title, isActive, children }) => {
  const [showText, setShowText] = useState(isActive ?? false);

  return (
    <Container>
      <div onClick={() => setShowText(!showText)}>
        <h4>{title}</h4>
        <button type="button" onClick={() => setShowText(!showText)}>
          {showText ? <IconMinusBlack /> : <IconPlusBlack />}
        </button>
      </div>
      {showText && children}
    </Container>
  );
};
