import styled from "styled-components";
import bgLpScreenGame from "../../assets/images/bg-lp-screen-game.webp";
import bgLpScreenHome from "../../assets/images/bg-lp-screen-home.webp";
import heroBannerDesktop from "../../assets/images/hero-banner-desktop.jpeg";
import heroBannerMobile from "../../assets/images/hero-banner-mobile.jpeg";

export const Container = styled.div`
    p.infoCardTitle {
        text-align: center;
        font-size: var(--text-size-2xs);
    }

    div.grafismImage {
        display: flex;

        img {
            width: 100%;
        }
    }

    @media screen and (min-width: 1105px) {
        p.infoCardTitle {
            font-size: var(--text-size-md);
        }
    }
`

export const SectionTitle = styled.div`
    height: 128vw;
    max-height: calc(100vh - 165px);
    margin-top: 85px;
    background-image: url(${heroBannerMobile});
    background-color: var(--color-purple-400);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    .space {
        display: none;
    }

    div#hero-text {
        max-width: 1488px;
        margin: 0 auto;
        padding: 0 24px;
        /* margin: auto; */

        h2 {
            text-align: center;
            color: var(--color-white);
            font-size: clamp(var(--text-size-md), calc(9vw - var(--text-size-md)) ,var(--text-size-xlg));
            font-weight: 600;
            padding: 0 18px clamp(24px, 5vw, 40px);
        }

        .emphasis {
        color: var(--color-cane);
        }

        button {
            display: block;
        }
    }
    

    > div {
        width: 100%;
        margin: 0 auto;
        position: absolute;
        bottom: clamp(32px, 8.5vw, 80px);
        left: 50%;
        translate: -50%;
        /* padding-bottom: 100px; */
    }
    
    .buttons {
        display: none;
    }

    @media screen and (min-width: 1105px) {
        max-height: 814px;
        height: 90vh;
        max-width: none;
        background-image: url(${heroBannerDesktop});
        /* padding-top: 108px; */
        /* padding-bottom: 120px; */
        .space {
            display: inline;
        }

        > div {
            position: relative;
            top: 0;
            left: 0;
            translate: 0;
            }

        div#hero-text {
            padding-top: clamp(190px, calc(31vh - 10px) ,300px);
            display: flex;
            justify-content: start;
            flex-direction: column;

            button {
                max-width: 247px;
                margin: 0;
            }

            h2 {
                text-align: left;
                font-size: 38px;
                line-height: 44px;
                font-weight: 600;
                max-width: 609px;
                padding-left: 0;
                padding-right: 0;
            }

            p {
                font-size: var(--text-size-md);
                margin-top: 32px;
            }

            button {
                /* margin-top: 48px; */
                &:hover {
                    filter: brightness(1.1);
                }
            }
        }

        .buttons {
                width: 100%;
                max-width: 1488px;
                padding-top: 139px;
                padding-right: 24px;
                margin: 0 auto;
                display: flex;
                justify-content: end;
                align-items: center;
                flex-direction: row;

                p {
                    color: var(--color-black);
                    font-size: var(--text-size-xs);
                    cursor: default;
                }

                button.videoButton {
                    margin-left: 16px;
                    width: 72px;
                    height: 72px;
                    border: solid 1.5px var(--color-black);
                    background: transparent;
                    border-radius: 50%;
                    /* animation: 2s shadowVideoButton infinite ease-in-out; */
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    /* & {
                        @keyframes shadowVideoButton {
                            0% {
                                box-shadow: 0 0 1px var(--color-black);
                            }
                            50% {
                                box-shadow: 0 0 8px var(--color-black);
                            }
                            100% {
                                box-shadow: 0 0 1px var(--color-black);
                            }
                        }
                    } */
                }
            }
    }
    @media screen and (min-width: 1105px) and (max-width: 1450px) {
        div#hero-text {
             h2 {
                font-size: 36px;
                max-width: 550px;
            }
            /*
            .space {
                display: none;
            } */
        }

        .buttons {
            padding-top: clamp(0px, calc(31vh - 150px) ,95px);
        }
}
`

export const SectionScreenHome = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;    

    picture {
        display: flex;
        overflow: hidden;
        justify-content: center;
    }

    img {
        width: 162vw;
        max-width: 800px;
        margin-top: 64px;
        z-index: 1;
    }

    @media screen and (min-width: 1105px) {
        padding-bottom: 56px;
        display: flex;
        justify-content: center;
        align-items: center;

        picture {
            overflow: visible;
        }

        img {
            margin-top: -155px;
            width: 100%;
        }
    }
`

export const SectionInfo = styled.div`
    background: var(--color-white);
    padding-top: 48px;

    #platform {
        scroll-margin-top: 85px;
    }

    div.wrapper {
        width: 100%;
        overflow: auto;
        margin: 32px 0 64px;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    div.withLineBrake {
        h3 {
            text-wrap: balance;
            width: 85px;
        }
    }

    div.buttonCenter {
        padding: 0 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 32px;

        button {
            margin: 0;
        }
    }

    div.text {
        margin: auto;
        padding: 0 24px;
        text-align: center;

        h3,
        h4 {
            font-size: var(--text-size-md);
            line-height: 28px;
            font-weight: 700;
        }

        p {
            font-size: var(--text-size-2xs);
            color: var(--color-gray-600);
            margin-top: 16px;
        }
    }

    @media screen and (min-width: 1105px) {
        padding-top: 48px;

        div.wrapper {
        margin: 104px 0 144px;
        }

        div.withLineBrake {
            h3 {
                width: 100%;
            }
        }

        div.buttonCenter {
            margin-top: 48px;

        } button {
            max-width: 308px;
            height: 64px;
            font-size: var(--text-size-sm);
        }

        div.text {
            margin: auto;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;

            h3 {
                font-size: var(--text-size-xlg);
                line-height: 52px;
                font-weight: 700;
                width: 600px;

                @media screen and (min-width: 1105px) {
                    max-width: 650px;    
                }
            }

            h4 {
                font-size: var(--text-size-xlg);
                line-height: 52px;
                font-weight: 700;
                width: 857px;
                @media screen and (min-width: 1105px) {
                    max-width: 650px;    
                }
            }

            p {
                max-width: 650px;
                font-size: var(--text-size-md);
                margin-top: 32px;
                width: 754px;
                margin: auto;
                margin-top: 32px;
            }
        }
    }
`

export const InfocardContainer = styled.div`
    max-width: 1488px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    gap: 8px;
    width: fit-content;

    & {
        @media screen and (min-width: 850px) {
            justify-content: center;
        }
    }

    &::-webkit-scrollbar {
        display: none;
    }

    @media screen and (min-width: 1105px) {
        width: 100%;
        align-items: stretch;
    }
`

export const GreenButton = styled.button`
    /* margin-top: 32px; */
    margin: 0 auto;
    padding: 14px 16px;
    width: 100%;
    max-width: 328px;
    border: none;
    font-size: var(--text-size-xs);
    background: var(--color-cane);
    border-radius: 65px;
    font-weight: 400;

    &:hover {
        filter: brightness(1.1);
    }

    &.marginTop {
        margin-top: 48px;
    }

    &.marginBottom {
        margin-bottom: 48px;
    }

    @media screen and (min-width: 1105px) {
        font-size: var(--text-size-sm);
        height: 64px;
        line-height: 24px;
        padding: 20px 16px;
    }
`

export const BlackButton = styled.button`
    /* margin-top: 32px; */
    margin: 0 auto;
    padding: 14px 16px;
    width: 100%;
    max-width: 328px;
    border: none;
    color: var(--color-white);
    background: var(--color-black);
    border-radius: 65px;
    font-size: var(--text-size-xs);
    font-weight: 400;

    &:hover {
        filter: brightness(1.1);
    }

    &.marginTop {
        margin-top: 48px;
    }

    &.marginBottom {
        margin-bottom: 48px;
    }

    @media screen and (min-width: 1105px) {
        font-size: var(--text-size-sm);
        line-height: 24px;
        padding: 20px 48.5px;
    }
`

export const Infocard = styled.div`
    padding: 24px;
    background: var(--color-gray-100);
    border-radius: 16px;
    max-width: 366px;
    width: 25%;
    min-width: 233.5px;

    &:first-child {
        margin-left: 24px;
    }

    &:last-child {
        margin-right: 24px;
    }

    svg {
        margin-bottom: 8px;
        width: 32px;
        height: 32px;
    }

    h3 {
        margin-bottom: 16px;
        font-weight: 600;
        font-size: var(--text-size-xsm);
        line-height: 22px;
    }
    p {
        font-size: var(--text-size-2xs);
        line-height: 18px;
        color: var(--color-gray-500);
    }

    @media screen and (min-width: 1269px) {
        padding: 48px;

        svg {
            width: 48px;
            height: 48px;
        }

        h3 {
            width: 100%;
            font-size: var(--text-size-md);
            line-height: 28px;
        }

        p {
            font-size: var(--text-size-xsm);
            line-height: 22px;
        }
    }

    @media screen and (min-width: 1269px) and (max-width: 1500px) {
        padding: 36px;
    }
`

export const SectionScreenGame = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
        width: 162vw;
        max-width: 800px;
        margin-top: 32px;
    }

    @media screen and (min-width: 1105px) {
        padding-bottom: 56px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 100%;
            margin-top: 104px;
        }
    }
`

export const SectionContents = styled.div`
    background-color: var(--color-gray-100);

    h3 {
        /* text-wrap: stable; */
        font-size: var(--text-size-md);
        line-height: 28px; 
    }
    
    > div {
        max-width: 1488px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        margin: 0 auto;
        padding: 64px 24px;
        gap: 32px;

        > div {
            max-width: 924px;

            .line {
                margin: 24px 0;
                width: 56px;
                border-top: solid 1px var(--color-gray-600);
            }
        }
    }

    @media screen and (min-width: 1105px) {
            h3 {
                max-width: 484px;
                font-size: var(--text-size-xlg);
                line-height: 52px;
            }
            
            > div {
                padding: 144px 24px;
                flex-direction: row;
                gap: 16px; 

                .line {
                margin: 48px 0;
                }
            }
        }
    `

export const SectionCoupons = styled.div`
    background: linear-gradient( transparent 0%,transparent 30% , var(--color-gray-200) 55%), url(${bgLpScreenHome}) no-repeat center top -60px / clamp(375px, 90%, 800px); ;
    background-color: var(--color-gray-200);

    display: flex;
    align-items: end;
    justify-content: center;
    height: 662px;


    div.sectionCouponsImages {
        display: flex;
        justify-content: space-between;
        overflow: hidden;

        img {
            margin-top: -60px;
            width: 100%;
            max-width: 600px;
        }
    }

    div.sectionCouponsContent {
        padding: 0 46px;
        text-align: center;

        h3 {
            font-weight: 700;
            font-size: var(--text-size-md);
            line-height: 28px;
        }

        p {
            color: var(--color-gray-600);
            font-size: var(--text-size-2xs);
            margin-top: 16px;
        }

        button {
            margin-top: 32px;
            padding: 14px 16px;
            width: 100%;
            max-width: 360px;
            border: none;
            background: var(--color-cane);
            border-radius: 65px;
            font-size: var(--text-size-xs);
            font-weight: 400;
        }
    }

    @media screen and (max-width: 1104px) {
        padding-bottom: 56px;
    }

    @media screen and (min-width: 1105px) {
        background: url(${bgLpScreenHome}) no-repeat fixed left center / clamp(360px, calc(55% - 360px), 650px),
            url(${bgLpScreenGame}) no-repeat fixed right center / clamp(360px, calc(55% - 360px), 650px),
            var(--color-gray-200);
        height: 600px;
        display: flex;
        align-items: center;
        justify-content: center;

        div.sectionCouponsImages {
            display: none;
        }

        div.sectionCouponsContent {
            width: 720px;

            h3 {
                font-size: var(--text-size-xlg);
                line-height: 52px;
            }

            p {
                font-size: var(--text-size-md);
                margin-top: 32px;
            }

            button {
                margin-top: 48px;
                max-width: 308px;
                height: 64px;
                font-size: var(--text-size-sm);
            }
        }
    }
`

export const SectionFaq = styled.div`
    text-align: center;
    padding: 64px 24px;

    h3 {
        margin-bottom: 16px;
        font-size: var(--text-size-md);
        font-weight: 700;
    }

    h5 {
        font-size: var(--text-size-2xs);
        padding: 0 24px;
        font-weight: 400;
    }

    div.faqContainer {
        margin-top: 32px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    @media screen and (min-width: 1105px) {
        width: 100%;
        max-width: 1488px;
        margin: auto;
        padding: 144px 48px;

        h3 {
            font-size: var(--text-size-xlg);
            margin-bottom: 32px;
        }

        h5 {
            font-size: var(--text-size-md);
        }

        div.faqContainer {
            margin-top: 48px;
        }
    }
`

export const LpForm = styled.div`
    padding: 56px 48px;

    div.title {
        text-align: center;

        h3 {
            font-size: var(--text-size-md);
            font-weight: 700;
        }

        p {
            font-size: var(--text-size-2xs);
            margin-top: 24px;
        }
    }

    form {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;

        div {
            align-items: center;
            width: 100%;

            p {
                margin-top: 0;
            }

            &.checkbox {
                label {
                    width: 100%;

                    div {
                        width: 22px;
                    }

                    a {
                        text-decoration: underline;
                    }
                }
            }
        }

        button {
            margin-top: 32px;
            border: none;
            background: var(--color-cane);
            padding: 14px 16px;
            font-size: var(--text-size-xs);
            width: 100%;
            max-width: 271px;
            border-radius: 65px;
            font-weight: 400;

            &:disabled {
                background: var(--color-gray-400);
                color: var(--color-black);
            }
        }

        div.SelectForm {
            margin-bottom: 32px;

            label {
                color: var(--color-gray-400);
                font-size: var(--text-size-3xs);
            }
            div {
                position: relative;
                display: flex;
                align-items: center;

                svg {
                    position: absolute;
                    ${props => props.right && 'right: 0;'}
                }

                select {
                    padding: 8px 32px;
                    padding-left: 27px;
                    border: none;
                    border-radius: 0;
                    border-bottom: solid 1px var(--color-black);
                    width: 100%;
                    font-size: var(--text-size-xs);
                    background: transparent;
                    color: var(--color-gray-400);

                    &::placeholder {
                        color: var(--color-gray-400);
                        font-size: var(--text-size-xs);
                    }
                }
            }
        }
    }

    @media screen and (min-width: 1105px) {
        display: flex;
        justify-content: center;

        div.lpFormContent{
            width: 600px;

            div.title {
                h3 {
                    font-size: var(--text-size-xlg);
                }

                p {
                    font-size: var(--text-size-md);
                }
            }
        }
    } 
`

export const Breadcrumbs = styled.div`
    
    display: flex;
    align-items: center;

    span {
        font-size: var(--text-size-3xs);
        margin: 0 8px;
        color: var(--color-gray-500);
    }

    a {
        font-size: var(--text-size-3xs);
        color: var(--color-gray-500);

        &:last-child {
            color: var(--color-black);
        }
    }
`

export const LpSuccessTitle = styled.div`
    padding: 56px 44px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div.lpSuccesContent {
        h3 {
            font-size: var(--text-size-md);
            font-weight: 700;
        }

        svg {
            margin: 24px 0;
        }

        p {
            color: var(--color-gray-600);
            text-align: center;
        }
    }

    @media screen and (min-width: 1105px) {
        display: flex;
        justify-content: center;

        div.lpSuccesContent {
            width: 708px;

            h3 {
                font-size: var(--text-size-xlg);
                font-weight: 700;
                text-align: center;
            }

            svg {
                margin: 24px 0;
                width: 100%;
            }

            p {
                color: var(--color-gray-600);
                font-size: var(--text-size-md);
                text-align: center;
                width: 574px;
                margin: 0 auto;
            }
        }
    }
`

export const LpAbouText = styled.div`
    padding: 40px 48px 56px 48px;
    text-align: center;

    h3 {
        font-weight: 700;
        font-size: var(--text-size-md);
    }

    p {
        margin-top: 24px;
        font-size: var(--text-size-2xs);
    }
`