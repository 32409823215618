import { Link } from "react-router-dom";
import { IconNavbarHomeActive } from "../../assets/icons/IconNavbarHomeActive";
import { Breadcrumbs, Container, Products } from "./styles";
import ProductCard from "../../components/ProductCard/index";
import { productsText } from "../../utils/productsText";

export default function ProductsPage() {
  const keyValueProducts = Array.from(productsText.entries());

  return (
    <Container>
      <Breadcrumbs>
        <IconNavbarHomeActive />
        <span>/</span>
        <Link to="/">Home</Link>
        <span>/</span>
        <Link to={"/produtos/"}>Produtos</Link>
      </Breadcrumbs>

      <h1>Produtos</h1>
      <Products>
        {keyValueProducts.map((content) => (
          <ProductCard
            key={content[0]}
            content={content[1]}
          />
        ))}
      </Products>

      {/* <BlackButton>conferir mais conteúdos</BlackButton> */}
    </Container>
  );
}
