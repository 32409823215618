import styled from "styled-components"

export const Container = styled.div`
    margin: 85px auto 64px;
    padding: 0 24px;
    max-width: 1488px;

    h1 {
    line-height: 28px;
    font-size: var(--text-size-md);
    margin-top: 40px;
    margin-bottom: 32px;
    }

    > div > div {
        max-width: 100%;
    }

    @media screen and (min-width: 1105px) {
        margin-bottom: 144px;

        h1 {
            line-height: 52px;
            font-size: var(--text-size-xlg);
            margin-top: 80px;
            margin-bottom: 48px;
        }
    }
`

export const Breadcrumbs = styled.div`
    padding-top: 40px;
    display: flex;
    align-items: center;

    span {
        font-size: var(--text-size-3xs);
        margin: 0 8px;
        color: var(--color-gray-500);
    }

    a {
        font-size: var(--text-size-3xs);
        color: var(--color-gray-500);

        &:last-child {
            color: var(--color-black);
        }
    }
`