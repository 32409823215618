export const addInteraction = (name) => {
    try {
        window.dataLayer.push({
            event: "interaction",
            event_ga:
            {
                event_name: name,
                event_value: "",
                event_params: {}
            }
        });
    } catch { }
}

export const addCTAEvent = () => {
    try {
        window.dataLayer.push({
            event: "cta_apostar_agora",
            event_ga:
            {
                event_name: "",
                event_value: "",
                event_params: {}
            }
        });
    } catch { }
}