import { Link } from "react-router-dom";
import { IconNavbarHomeActive } from "../../assets/icons/IconNavbarHomeActive";
import { Breadcrumbs, Text, Container } from "./styles";
import { pagesText } from "../../utils/pagesText";

export const TextPage = ({ page }) => {
  return (
    <Container>
      <Breadcrumbs>
        <IconNavbarHomeActive />
        <span>/</span>
        <Link to="/">Home</Link>
        <span>/</span>
        <Link to="">{pagesText[page].title}</Link>
      </Breadcrumbs>
      <Text>{pagesText[page].content}</Text>
    </Container>
  );
};
