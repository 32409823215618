export const IconNavbarHomeActive = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2822_4352)">
                <path d="M8.14054 0.662245C8.04688 0.658809 7.95313 0.658809 7.85947 0.662245C7.24122 0.684925 6.68451 0.895157 6.0741 1.24512C5.47926 1.58616 4.79444 2.08371 3.92552 2.71502L3.92551 2.71503L3.87828 2.74935L3.87827 2.74936C3.00934 3.38066 2.3245 3.87822 1.81634 4.33857C1.29488 4.81095 0.922902 5.27545 0.710281 5.85644C0.678072 5.94445 0.649102 6.03361 0.623429 6.12374C0.453948 6.71874 0.481857 7.31318 0.626064 8.00185C0.766596 8.67297 1.02818 9.47804 1.36009 10.4995L1.37813 10.5551L1.37814 10.5551C1.71003 11.5765 1.97161 12.3816 2.25239 13.0072C2.54051 13.6491 2.86733 14.1464 3.35418 14.5281C3.42793 14.586 3.50377 14.6411 3.58156 14.6933C4.09507 15.0384 4.66903 15.1955 5.36856 15.2712C6.05025 15.3449 6.89673 15.3449 7.97075 15.3449H7.97081H8.0292H8.02926C9.10328 15.3449 9.94976 15.3449 10.6314 15.2712C11.331 15.1955 11.9049 15.0384 12.4184 14.6933C12.4962 14.6411 12.5721 14.586 12.6458 14.5281C13.1327 14.1464 13.4595 13.6491 13.7476 13.0072C14.0284 12.3816 14.29 11.5765 14.6219 10.555L14.6399 10.4995L14.6399 10.4994C14.9718 9.47804 15.2334 8.67293 15.3739 8.00185C15.5182 7.31318 15.5461 6.71874 15.3766 6.12374C15.3509 6.03361 15.3219 5.94445 15.2897 5.85644C15.0771 5.27545 14.7051 4.81095 14.1837 4.33857C13.6755 3.87822 12.9907 3.38066 12.1217 2.74935L12.0745 2.71502L12.0745 2.71501C11.2056 2.08371 10.5207 1.58616 9.92591 1.24512C9.3155 0.895157 8.75879 0.684925 8.14054 0.662245Z" fill="#262626" />
            </g>
            <defs>
                <clipPath id="clip0_2822_4352">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}