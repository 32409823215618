import { Link } from "react-router-dom";
import { IconNavbarHomeActive } from "../../assets/icons/IconNavbarHomeActive";
import { Breadcrumbs, Container } from "./styles";
import { LpFaq } from "../../components/LpFaq";

export default function FAQPage() {
  return (
    <Container>
      <Breadcrumbs>
        <IconNavbarHomeActive />
        <span>/</span>
        <Link to="/">Home</Link>
        <span>/</span>
        <Link to="/perguntas-frquentes">perguntas frequentes</Link>
      </Breadcrumbs>

      <h1>Perguntas frequentes</h1>
      <div className="faqContainer">
        {/* sobre o zé da sorte */}
        <LpFaq title="como funciona o zé da sorte?">
          <p>
            1) Você cadastra seus dados e valida sua conta <br />
            2) Escolhe seu jogo e os números da sorte <br />
            3) Faz o pagamento e espera o comprovante de registro do jogo para torcer <br />
            4) Seu jogo é registrado e o prêmio transferido em até 5 dias úteis
          </p>
        </LpFaq>

        <LpFaq title="o zé da sorte é seguro?">
          <p>
            Sim. Somos muito seguros. Registramos suas fezinhas em lotéricas oficiais da Caixa. Na
            plataforma você acessa o comprovante de pagamento e registro de jogo. Além disso, não
            divulgamos seus dados e utilizamos o Mercado Pago para pagamento dos jogos.
          </p>
        </LpFaq>
        <LpFaq title="quais as vantagens de jogar pelo zé da sorte?">
          <p>
            <strong>Praticidade</strong>: Com o Zé da Sorte você joga online e não precisa ir na
            lotérica fazer sua fezinha.
            <br />
            <strong>Comodidade</strong>: Jogue de onde estiver e quando quiser.
            <br />
            <strong>Rapidez</strong>: Sua fezinha em poucos minutos: escolha o jogo, as dezenas, a
            forma de pagamento, e pronto. Agora é só torcer.
            <br />
            <strong>Segurança</strong>: Todos os jogos são conferidos e registrados em loterias
            oficiais da Caixa. Além disso, utilizamos a plataforma do Mercado Pago para você pagar
            os jogos.
          </p>
        </LpFaq>

        {/* minha conta */}
        <LpFaq title="como faço meu cadastro?">
          <p>
            Acesse a plataforma e clique em criar conta. Insira seus dados e fique de olho em seus
            e-mails para ativar seu cadastro.
          </p>
        </LpFaq>
        <LpFaq title="não consigo acessar minha conta. o que faço?">
          <p>
            Verifique se as informações de login estão corretas. Se não conseguir, tente recuperar a
            senha. Se ainda assim não der certo, fale com nosso time pelo e-mail
            contato@zedasorte.com.br
          </p>
        </LpFaq>
        <LpFaq title="esqueci minha senha. o que faço?">
          <p>
            Na tela inicial da plataforma, clique em esqueci a senha e siga os passos do e-mail que
            você vai receber.
          </p>
        </LpFaq>
        {/* formas de pagamentos */}
        <LpFaq title="tem um tempo limite para pagar o jogo?">
          <p>O tempo limite para fazer o jogo e concluir o pagamento é de 30 minutos.</p>
        </LpFaq>
        <LpFaq title="como faço o pagamento do meu jogo?">
          <p>
            Você pode pagar seu jogo por meio do Pix ou cartão de crédito. Utilizamos o Mercado Pago
            para oferecer mais segurança na hora de você pagar sua fezinha.
          </p>
        </LpFaq>
        <LpFaq title="posso pagar minha aposta em uma lotérica da caixa?">
          <p>
            Não. Sua fezinha deve ser paga diretamente na plataforma do Zé da Sorte. Utilize sua
            Chave Pix ou cartão de crédito.
          </p>
        </LpFaq>

        {/* compra de jogo */}
        <LpFaq title="tem um valor mínimo para jogar?">
          <p>
            Não. No Zé da Sorte você paga apenas o valor que jogar e faz quantas fezinhas quiser.{" "}
          </p>
        </LpFaq>
        <LpFaq title="existe um horário limite para jogar?">
          <p>
            Não. Com o Zé da Sorte você joga qualquer dia, em qualquer horário. Em dias de concurso,
            se você jogar até as 16h (horário de Brasília), os jogos serão transmitidos para a
            lotérica no mesmo dia; após esse horário, os jogos serão válidos para o concurso
            seguinte.
          </p>
        </LpFaq>
        <LpFaq title="onde vejo os resultados dos jogos?">
          <p>
            Acesse meus jogos, no menu da plataforma, e clique no jogo que deseja ver o resultado.
          </p>
        </LpFaq>
        <LpFaq title="minha compra foi recusada ou cancelada. o que faço?">
          <p>
            Verifique se colocou as informações corretas de Chave Pix ou cartão de crédito. Antes de
            jogar novamente, confira o extrato da sua conta para ver se o pagamento realmente não
            foi feito. Se ainda assim ficar com dúvidas, fale com a gente pelo e-mail
            contato@zedasorte.com.br. Importante lembrar que só faremos registro da sua aposta após
            a confirmação de pagamento.
          </p>
        </LpFaq>
        <LpFaq title="como vejo o comprovante de pagamento do meu jogo?">
          <p>
            Acesse meus jogos, no menu da plataforma, e clique no jogo que deseja ver o comprovante.
          </p>
        </LpFaq>
        <LpFaq title="como vejo se meu jogo foi confirmado?">
          <p>
            Assim que sua compra for confirmada, você receberá um e-mail de confirmação de registro
            e o comprovante estará disponível em meus jogos. É importante lembrar que só faremos
            registro do jogo após a confirmação de pagamento.
          </p>
        </LpFaq>
        <LpFaq title="qual é o prazo para confirmação do pagamento do meu jogo?">
          <p>
            Se você pagar via Pix, a aprovação será feita em poucos minutos. Mas se você pagar
            utilizando cartão de crédito demora um pouco mais, pois depende da aprovação da
            operadora do cartão.
          </p>
        </LpFaq>
        <LpFaq title="posso repetir algum jogo que já fiz?">
          <p>
            Sim. Acesse meus jogos e clique em repetir. Depois, finalize o jogo e faça o pagamento
            normalmente.
          </p>
        </LpFaq>
        <LpFaq title="o que é o palpite do zé?">
          <p>
            Com o Palpite do Zé a plataforma escolhe os números automaticamente e de forma aleatória
            para você! É uma maneira rápida, fácil e segura de apostar.
          </p>
        </LpFaq>
        <LpFaq title="O que é jogo responsável?">
          <p>
            É um conjunto de ações adotado por quem administra loterias e apostas com o objetivo de:
          </p>
          <p>
            - Evitar que menores de 18 anos façam apostas;
            <br />- Orientar sobre prevenção e tratamento aos danos relacionados com jogos;
            <br />- Orientar quanto aos possíveis danos relacionados aos jogos e seus tratamentos.
          </p>
          <br />
          <p>Quem joga com responsabilidade tem:</p>
          <p>- Informações sobre as reais probabilidades de ganhar ou perder;
            <br />- Aposta para se divertir e não para ganhar dinheiro ou afastar problemas;
            <br />- Não pede dinheiro emprestado para jogar;
            <br />- Não permite que o jogo afete suas relações com famílias e amigos.
          </p>
          <p>Fonte: Caixa Econômica Federal</p>
        </LpFaq>

        {/* premiação */}
        <LpFaq title="como recebo o valor do prêmio?">
          <p>
            O valor do prêmio será transferido automaticamente, em até 5 dias úteis após a data do
            concurso, para os dados bancários de sua titularidade utilizados em seu cadastro.
          </p>
        </LpFaq>
        <LpFaq title="como vou saber se ganhei?">
          <p>
            Após o concurso enviamos os resultados por email. Você também pode acessar o site e ir
            em "resultados"
          </p>
        </LpFaq>
        <LpFaq title="só eu posso receber o prêmio?">
          <p>
            Sim. O prêmio é nominal e vinculado ao CPF do cadastro da aposta, portanto, apenas a
            pessoa titular do CPF pode receber o prêmio. O valor do prêmio será transferido
            automaticamente, em até 5 dias úteis após o concurso, para os dados bancários de sua
            titularidade que utilizou em seu cadastro.
          </p>
        </LpFaq>
        <LpFaq title="o zé da sorte cobra alguma taxa para eu receber o prêmio?">
          <p>
            Depois de receber o valor da Caixa Econômica Federal, o Zé da Sorte cobra uma taxa
            limitada a apenas 0,5% sobre o prêmio para cobertura de taxas administrativas e
            operacionais.
          </p>
        </LpFaq>

        {/* loterias */}
        <LpFaq title="como funciona a teimosinha do zé?">
          <p>
            Com a Teimosinha você participa de até 10 concursos jogando com os mesmos números. É
            mais rapidez e facilidade pra você!
          </p>
          <p>
            Para fazer sua Teimosinha acesse o Zé da Sorte, escolha sua loteria (mega-sena, dupla
            sena…) e selecione as dezenas. Depois, escolha se quer um jogo único ou teimosinha do
            zé. Por fim, escolha em quantos concursos quer participar.
          </p>
        </LpFaq>
        <LpFaq title="como jogar na mega-sena?">
          <p>
            Para realizar uma aposta simples selecione 6 números (dezenas). Você ganha se acertar os
            6 números sorteados, 4 ou 5 números.
          </p>
          <p>
            Se quiser aumentar suas chances de ganhar, jogue mais que 6 números. Quanto mais
            dezenas, mais chances você tem de ganhar, porém, mais alto será o valor do jogo.
          </p>
          <p>
            Você também pode jogar utilizando o Palpite do Zé. É só clicar no botão “palpite” e ver
            quais números aparecem. Se quiser trocar os números é só clicar novamente em “palpite”,
            e pronto! Conclua o jogo seguindo os passos que aparecem na tela.
          </p>

          <br />
          <p>
            <strong>dias e horários</strong>
          </p>
          <p>
            Os concursos da Mega-Sena são realizados três vezes por semana, às terças, quintas e aos
            sábados.
          </p>

          <br />
          <p>
            <strong>acumulação</strong>
          </p>
          <p>
            Se ninguém ganhar em qualquer faixa, o valor acumula para o concurso seguinte na
            respectiva faixa de premiação.
          </p>
          <p>
            Os prêmios prescrevem 90 dias após a data do concurso. Após esse prazo, os valores são
            repassados ao tesouro nacional para aplicação no Fundo de Financiamento ao Estudante do
            Ensino Superior (FIES).
          </p>
          <p>Fonte: Caixa Econômica Federal</p>
        </LpFaq>
        <LpFaq title="como jogar na dupla sena?">
          <p>
            Com apenas um bilhete da Dupla Sena você tem o dobro de chances de ganhar: são dois
            sorteios por concurso e você ganha acertando 3, 4, 5 ou 6 números no primeiro e/ou
            segundo sorteios.
          </p>
          <p>
            Marque de 6 a 15 números entre os 50 disponíveis. Você ainda pode concorrer com a mesma
            aposta por até 10 concursos consecutivos com a teimosinha do zé.
          </p>
          <p>
            Há também o Concurso Especial Dupla de Páscoa, que acontece todo ano, no sábado que
            antecede o domingo de páscoa. Saiba mais sobre esse concurso no site da Caixa Econômica.
          </p>

          <br />
          <p>
            <strong>dias e horários</strong>
          </p>
          <p>A Dupla Sena é sorteada às segundas, quartas e sextas-feiras, a partir das 20h.</p>
          <br />
          <p>
            <strong>acumulação</strong>
          </p>
          <p>
            Não existindo fezinha premiada na 7ª faixa (0 acerto), o prêmio acumula para o concurso
            subsequente, na 1ª faixa de premiação (20 acertos). Nas demais faixas, o prêmio acumula
            na respectiva faixa de premiação.
          </p>
          <p>Fonte: Caixa Econômica Federal</p>
        </LpFaq>

        {/* duvidas gerais */}
        <LpFaq title="o zé da sorte é uma lotérica ou loteria?">
          <p>
            Não. O Zé da Sorte atua como um serviço de intermediação, agindo em nome da pessoa que
            aposta na plataforma para sua conveniência e comodidade, realizando apostas junto às
            lotéricas.
          </p>
        </LpFaq>
        <LpFaq title="como o zé da sorte rentabiliza sua operação?">
          <p>
            O Zé da Sorte faz uma cobrança limitada a apenas 0,5% sobre o valor do prêmio. Esse
            custo é utilizado para cobertura de taxas administrativas e operacionais. Além disso,
            possuímos uma taxa variável de intermediação sobre o valor de cada aposta.
          </p>
        </LpFaq>
      </div>
    </Container>
  );
}
