import { Link } from "react-router-dom";
import { IconNavbarHomeActive } from "../../assets/icons/IconNavbarHomeActive";
import { BlackButton, Breadcrumbs, Container, Contents } from "./styles";
import ContentCard from "./../../components/ContentCard/index";
import { contentsLink } from "../../components/LpFooter";
import { contentsText } from "../../utils/contentsText";

export default function ContentsPage() {
  const keyValueContents = Array.from(contentsText.entries());

  return (
    <Container>
      <Breadcrumbs>
        <IconNavbarHomeActive />
        <span>/</span>
        <Link to="/">Home</Link>
        <span>/</span>
        <Link to={contentsLink}>dicas do zé</Link>
      </Breadcrumbs>

      <h1>dicas do zé</h1>
      <Contents>
        {keyValueContents.map((content) => (
          <ContentCard
            key={content[0]}
            content={content[1]}
          />
        ))}
      </Contents>

      {/* <BlackButton>conferir mais conteúdos</BlackButton> */}
    </Container>
  );
}
