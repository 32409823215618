export const IconInstagramCircle = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M16 0.864865C7.64187 0.864865 0.865612 7.64073 0.865612 16C0.865612 24.3593 7.64187 31.1351 16 31.1351C24.3581 31.1351 31.1344 24.3593 31.1344 16C31.1344 7.64073 24.3581 0.864865 16 0.864865ZM0 16C0 7.16381 7.16308 0 16 0C24.8369 0 32 7.16381 32 16C32 24.8362 24.8369 32 16 32C7.16308 32 0 24.8362 0 16Z" fill="#A5A3A9" />
            <path d="M24.876 13.47C24.8668 12.634 24.8638 11.7918 24.6794 10.9711C24.2708 9.15152 23.1985 7.93743 21.398 7.39339C20.5592 7.14135 19.6928 7.11984 18.8325 7.10754C17.2993 7.08603 15.7661 7.08296 14.2329 7.09218C13.2805 7.09832 12.3249 7.08296 11.3847 7.25815C9.46443 7.6147 8.17091 8.69969 7.59943 10.5931C7.35056 11.4168 7.34134 12.2713 7.31676 13.1196C7.27682 14.6533 7.29526 16.1871 7.3014 17.7208C7.30448 18.686 7.29833 19.6542 7.46731 20.6101C7.73769 22.1285 8.50889 23.3026 9.89151 24.028C10.6904 24.446 11.5599 24.5874 12.4509 24.6335C13.726 24.695 15.0011 24.6827 16.2792 24.6827C16.8261 24.6827 17.373 24.6888 17.9199 24.6827C18.7833 24.6704 19.6467 24.6673 20.507 24.5628C22.6516 24.3077 24.1724 22.9583 24.661 20.8744C24.8607 20.0199 24.8637 19.1439 24.873 18.2741C24.8945 16.6727 24.8945 15.0714 24.876 13.47ZM23.2169 19.7986C23.1401 20.7638 22.8513 21.6367 22.0678 22.276C21.5578 22.694 20.9617 22.8846 20.3257 22.9737C19.6344 23.0721 18.94 23.0721 18.2425 23.0782C16.8046 23.0905 15.3667 23.0936 13.9288 23.0782C13.1084 23.069 12.2788 23.0874 11.4708 22.903C10.0574 22.5803 9.16947 21.5752 9.00356 20.1306C8.9206 19.4236 8.90524 18.7167 8.90217 18.0067C8.88988 16.5805 8.88681 15.1574 8.90217 13.7313C8.91139 12.929 8.89295 12.1207 9.06194 11.3307C9.37533 9.86461 10.3708 8.97017 11.861 8.7919C12.5461 8.70891 13.2344 8.69662 13.9226 8.69047C15.3667 8.67818 16.8138 8.6751 18.2579 8.69047C19.0721 8.69969 19.8925 8.67818 20.6975 8.8626C22.1354 9.19148 23.008 10.172 23.1862 11.6381C23.2722 12.3297 23.2783 13.0243 23.2906 13.722C23.3029 14.4443 23.2937 15.1666 23.2937 15.889C23.2845 17.1922 23.3214 18.4985 23.2169 19.7986Z" fill="#A5A3A9" />
            <path d="M16.1225 11.3615C13.643 11.34 11.5906 13.3593 11.5752 15.8336C11.5599 18.3417 13.5631 20.3888 16.0457 20.4041C18.5744 20.4164 20.5991 18.4186 20.6145 15.892C20.6268 13.4147 18.6143 11.383 16.1225 11.3615ZM16.144 18.8089C14.4542 18.8089 13.1699 17.5241 13.1729 15.8398C13.176 14.263 14.4972 12.9628 16.0979 12.9628C17.7294 12.9628 19.0199 14.2691 19.0199 15.9228C19.0168 17.4842 17.6987 18.8089 16.144 18.8089Z" fill="#A5A3A9" />
            <path d="M20.7835 10.132C20.1905 10.132 19.7204 10.6084 19.7296 11.2016C19.7358 11.7918 20.2028 12.2498 20.7896 12.2467C21.3857 12.2436 21.8527 11.7733 21.8466 11.1771C21.8404 10.5992 21.3703 10.132 20.7835 10.132Z" fill="#A5A3A9" />
        </svg>

    )
}