import { Card } from "./styles";
import { Link } from "react-router-dom";

export default function CompactProductCard({ content }) {
  return (
    <Card>
      <div>
        <h2>{content.title}</h2>
        <div className="line" />
        <p>{content.description}</p>
      </div>
      <Link to={"/produtos/"+content.link}>conferir conteúdo</Link>
    </Card>
  );
}
