import styled from "styled-components";

export const Container = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: var(--color-white);
    border-bottom: 1px solid var(--color-gray-200);
    z-index: 2;

    div.itensContainer {
        margin: 0 auto;
        max-width: 1488px;
        padding: 16px 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    a:hover,
    button:hover {
        filter: brightness(1.1);
    }

    div.linksContainer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex: 1;

        div {
            display: flex;
            padding-left: 24px;

            &.links {
                justify-content: center;
                align-items: center;
                flex: 1;
            }

            &.buttons {
                justify-content: flex-end;
            }
        }

        a:not(:last-child) {
            margin-right: 24px;
        }

        span {
            color: var(--color-black);
        }
    }

    button.menu {
        border: none;
        border-radius: 50%;
        background: var(--color-white);
        width: 48px;
        height: 48px;
        display: none;
        justify-content: center;
        align-items: center;
    }

    @media screen and (max-width: 1104px) {
        div.linksContainer div.links,
        div.linksContainer div.buttons{
            display: none;
        }
        button.menu {
            display: flex;
        }
    }
`

export const BlackButton = styled.button`
    margin: 0 auto;
    padding: 14px 16px;
    border: solid 1px var(--color-white);
    background: var(--color-black);
    border-radius: 65px;
    font-size: var(--text-size-xs);
    font-weight: 400;
    color: var(--color-white);
    margin-right: 8px;
`

export const TransparentButton = styled.button`
    margin: 0 auto;
    padding: 14px 16px;
    border: solid 1.4px var(--color-black);
    background: transparent;
    border-radius: 65px;
    font-size: var(--text-size-xs);
    font-weight: 400;
`