import styled from "styled-components";

export const GreenButton = styled.button`
    /* margin-top: 32px; */
    margin: 0 auto;
    padding: 14px 16px;
    width: 100%;
    max-width: 328px;
    border: none;
    font-size: var(--text-size-xs);
    background: var(--color-cane);
    border-radius: 65px;
    font-weight: 400;

    &:hover {
        filter: brightness(1.1);
    }

    &.marginTop {
        margin-top: 28px;
    }

    &.marginBottom {
        margin-bottom: 28px;
    }

    @media screen and (min-width: 1105px) {
        font-size: var(--text-size-sm);
        height: 64px;
        line-height: 24px;
        padding: 20px 16px;
    }
`
