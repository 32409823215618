import { BrowserRouter, Routes as Router, Route, Navigate } from "react-router-dom";
import { ScrollToTop } from "./components/ScrollToTop";
// import { CookiesChoice } from "./components/CookiesChoice";
import { Index } from "./pages/Index";
import { TextPage } from "./pages/TextPage";
import ContentsPage from "./pages/Contents";
import ContentPage from "./pages/Content";
import ProductsPage from "./pages/Products";
import ProductPage from "./pages/Product";
import FAQPage from "./pages/FAQ";
import { LpFooter } from "./components/LpFooter";
import { LpHeader } from "./components/LpHeader";
import bgLpGrafismMobile from "./assets/images/bg-lp-grafism-mobile.jpeg";
import bgLpGrafismDesktop from "./assets/images/bg-lp-grafism-desktop.jpeg";
import { GrafismImage } from "./styles";
import { useState } from "react";
import { LpMenu } from "./components/LpMenu";
import { Baixar } from "./pages/Baixar";

export const Routes = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [showVideo, setShowVideo] = useState(false);

  function setViewMenu() {
    setShowMenu(() => !showMenu);
  }
  // <Outlet context={[ showVideo, setShowVideo ]}/>
  return (
    <BrowserRouter>
      {showMenu && <LpMenu setViewMenu={setViewMenu} setShowVideo={setShowVideo} />}
      <LpHeader setViewMenu={setViewMenu} iconShowMenu={showMenu} />
      <ScrollToTop>
        <Router>
          <Route path="/" id="home" element={<Index showVideo={showVideo} setShowVideo={setShowVideo} />} />
          <Route path="/quem-somos" element={<TextPage page="about" />} />
          <Route path="/privacidade" element={<TextPage page="privacy" />} />
          <Route path="/termos-de-uso" element={<TextPage page="terms" />} />
          <Route path="/cookies" element={<TextPage page="cookies" />} />
          <Route path="/dicas-do-ze" element={<ContentsPage />} />
          <Route path="/dicas-do-ze/:contentId" element={<ContentPage />} />
          <Route path="/produtos" element={<ProductsPage />} />
          <Route path="/produtos/:productId" element={<ProductPage />} />
          <Route path="/perguntas-frequentes" element={<FAQPage />} />
          <Route path="/baixar" element={<Baixar />} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </Router>
      </ScrollToTop>
      <GrafismImage>
        <picture>
          <source media="(min-width: 1000px)" srcSet={bgLpGrafismDesktop} />
          <source media="(max-width: 999px)" srcSet={bgLpGrafismMobile} />
          <img loading="lazy" src={bgLpGrafismDesktop} alt="" />
        </picture>
      </GrafismImage>
      <LpFooter />
    </BrowserRouter>
  );
};
