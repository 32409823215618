import styled from "styled-components"

export const Container = styled.div`
    max-width: 1488px;
    padding: 0 24px;
    margin: 85px auto 64px;

    p,li {
    text-transform: none;
    }

    h1 {
    color: var(--color-black);
    font-size: var(--text-size-md);
    line-height: 28px;
    margin-top: 40px;
    margin-bottom: 32px;
    }

    p, li {
    color: var(--color-black);
    font-size: var(--text-size-2xs);
    line-height: 24px;
    }

    ol {
    margin: 32px 0;
    }

    li {
    margin-left: 18px;

    &:not(:last-child) {
        margin-bottom: 8px;
    }
    }

    p {
    margin-bottom: 16px;
    }

    h2 {
    font-size: var(--text-size-xsm);
    line-height: 22px;
    margin-top: 32px;
    margin-bottom: 16px;
    }

@media screen and (min-width: 1105px) {
    margin-bottom: 144px;

    h1 {
        line-height: 52px;
        font-size: var(--text-size-xlg);
        margin-top: 80px;
        margin-bottom: 48px;
    }

    p,li {
        font-size: var(--text-size-sm);
        line-height: 24px;
    }

    li {
        margin-left: 28px;
        margin-bottom: 0;
    }

    h2 {
        font-size: var(--text-size-lg);
        line-height: 36px;
        margin-top: 48px;
        margin-bottom: 24px;
    }
}
`

export const Breadcrumbs = styled.div`
    padding-top: 40px;
    display: flex;
    align-items: center;

    span {
        font-size: var(--text-size-3xs);
        margin: 0 8px;
        color: var(--color-gray-500);
    }

    a {
        font-size: var(--text-size-3xs);
        color: var(--color-gray-500);

        &:last-child {
            color: var(--color-black);
        }
    }
`

export const SeeMore = styled.div`
    padding: 64px 0;
    background-color: var(--color-gray-100);

    h1 {
    font-size: var(--text-size-md);
    line-height: 28px;
    padding-left: 24px;
    }

    .wrapper {
        overflow: auto;

        > div {
        max-width: 1488px;
        width: fit-content;
        margin: 32px auto 0;
        display: flex;
        gap: 8px;
        }
    }

    @media screen and (min-width: 1105px) {
        padding: 144px 24px;

    h1 {
        text-align: center;
        line-height: 52px;
        font-size: var(--text-size-xlg);
        margin-bottom: 48px;
    }

    .wrapper > div {
        margin: 48px auto 0;
    }
}
`

export const BlackButton = styled.button`
    display: block;
    margin: 48px auto 0;
    padding: 14px 16px;
    width: 100%;
    max-width: 328px;
    border: none;
    color: var(--color-white);
    background: var(--color-black);
    border-radius: 65px;
    font-size: var(--text-size-xs);
    font-weight: 400;

    &:hover {
        filter: brightness(1.1);
    }

    &.marginTop {
        margin-top: 48px;
    }

    &.marginBottom {
        margin-bottom: 48px;
    }

    @media screen and (min-width: 1105px) {
        font-size: var(--text-size-sm);
        line-height: 24px;
        padding: 20px 46px;
    }
`
export const NotFound = styled.div`
    margin-top: 85px;

    @media screen and (min-width: 1105px) {
    margin-top: 0;
    }
`