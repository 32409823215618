import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        text-transform: lowercase;
        box-sizing: border-box;
        font-family: 'Space Grotesk';
        color: var(--color-black);
        outline: none;
    }

    :root {
        /* primary colors */
        --color-purple: #7D08FF;
        --color-black: #262626;
        --color-cane: #B0E737;
        --color-white: #FFFFFF;

        /* neutral colors */
        --color-gray-100: #F9F9F9;
        --color-gray-200: #E8E8EA;
        --color-gray-300: #D2D1D4;
        --color-gray-400: #A5A3A9;
        --color-gray-500: #77757F;
        --color-gray-600: #4A4754;
        --color-gray-700: #201B1C;

        /* secondary purple color */
        --color-purple-100: #EECDFF;
        --color-purple-300: #BF6AFF;
        --color-purple-400: #A645FF;
        --color-purple-500: #7D08FF;
        --color-purple-600: #6005DB;

        /* secondary tifani color */
        --color-tifani-100: #D8FEEC;
        --color-tifani-500: #3DF2E0;

        /* secondary green colors */
        --color-green-500: #3FF269;

        /* secondary tifani colors */
        --color-cane-100: #F6FDD7;
        --color-cane-300: #DBF786;
        --color-cane-500: #B0E737;
        --color-cane-600: #90C628;

        /* secondary mustard colors */
        --color-mustard-100: #FDFCCE;
        --color-mustard-500: #E5DE12;
        --color-mustard-600: #C4BE0D;

        /* secondary magenta colors */
        --color-magenta-100: #FECBD0;
        --color-magenta-500: #FE007D;
        --color-magenta-600: #DA0081;

        /* secondary pink colors */
        --color-pink-100: #FEDDE9;
        --color-pink-500: #F354CB;
        --color-pink-600: #D03DBA;

        /* alert colors */
        --color-alert-success: #94CE0E;
        --color-alert-info: #00DDE5;
        --color-alert-warning: #FFCC02;
        --color-alert-danger: #FF5214;

        /* product colors */
        --color-mega-sena: #7FAB30;
        --color-lotomania: #ED7043;
        --color-quina: #3652E6;
        --color-lotofacil: #B459E2;
        --color-super-sete: #B5DB6A;
        --color-dupla-sena: #DB4745;
        --color-timemania: #E3D668;
        --color-dia-de-sorte: #D9BC66;
        --color-loteria-federal: #29439C;
        --color-loteca: #ED5C56;
        --color-milionaria: #ED5C56;

        /* text sizes*/
        --text-size-4xs: 10px;
        --text-size-3xs: 12px;
        --text-size-2xs: 14px;
        --text-size-xs: 16px;
        --text-size-xsm: 18px;
        --text-size-sm: 20px;
        --text-size-md: 24px;
        --text-size-lg: 32px;
        --text-size-xlg: 48px;
    }

    html {
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -webkit-text-size-adjust: 100%;
    }

    body.no-scroll {
        overflow: hidden;
    }

    button:disabled,
    input:disabled,
    textarea:disabled {
        cursor: not-allowed;
    }

    a {
        text-decoration: none;
    }

    a,
    button {
        cursor: pointer;
    }

    a,
    button,
    input,
    textarea,
    select,
    div {
        -webkit-tap-highlight-color: transparent;
    }
`