import styled from "styled-components";
import bgLpTitleMobile from "../../assets/images/bg-lp-title-mobile.png";
import bgLpTitleDesktop from "../../assets/images/bg-lp-title-desktop.png";
import bgLpScreenGame from "../../assets/images/bg-lp-screen-game.webp";
import bgLpScreenHome from "../../assets/images/bg-lp-screen-home.webp";
import bgLogoBig from "../../assets/images/bg-logo-big.png";

export const Container = styled.div`
    max-width: 1488px;
    padding: 0 24px;
    margin: 0 auto;

    /* h1 {
        font-weight: 700;
        font-size: var(--text-size-md);
        line-height: 28px;
    } */

    @media screen and (min-width: 1105px) {
        h1 {
        font-size: var(--text-size-xlg);
        line-height: 52px;
        }

        p {
            font-size: var(--text-size-sm);
            line-height: 24px;
        }
        
    }
`;

export const SectionTitle = styled.div`
    padding-top: 80px;
    background: var(--color-black) url(${bgLpTitleMobile}) no-repeat right top / 50% 100%;

    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 75%;
        max-width: 748px;
        margin: 24px auto 0 auto;
        padding-bottom: 100px;

        h2,
        p {
            color: var(--color-white);
            text-align: center;
        }

        h2 {
            font-size: var(--text-size-md);
            font-weight: 700;
        }

        p {
            margin-top: 16px;
            font-size: var(--text-size-2xs);
        }

        button {
            margin-top: 32px;
            border: none;
            background: var(--color-cane);
            padding: 14px;
            border-radius: 65px;
            width: 100%;
            max-width: 279px;
            font-size: var(--text-size-xs);
            font-weight: 400;
        }

        @media screen and (min-width: 1105px) {
            h2 {
                font-size: var(--text-size-xlg);
                font-weight: 700;
            }

            p {
                font-size: var(--text-size-md);
            }

            button {
                max-width: 253px;
                height: 64px;
                font-size: var(--text-size-sm);

                &:hover {
                    filter: brightness(1.1);
                }
            }
        }
    }    

    @media screen and (min-width: 1105px) {
        background: var(--color-black) url(${bgLpTitleDesktop}) no-repeat right top / 50% 100%;
        padding-top: 108px;
        padding-bottom: 120px;
        height: 864px;
    }
`

export const SectionScreenHome = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 56px;
    margin-top: -1px;
    background: var(--color-black) url(${bgLogoBig}) no-repeat center top / 140%;

    img {
        margin-top: -64px;
        width: 90%;
        max-width: 436px;
    }

    @media screen and (min-width: 1105px) {
        background: var(--color-black);
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            margin-top: -360px;
            width: 100%;
            max-width: 900px;
        }
    }
`

export const SectionInfo = styled.div`
    background: var(--color-white);
    padding-top: 56px;

    div.text {
        margin: auto;
        text-align: center;

        h3 {
            font-size: var(--text-size-md);
            font-weight: 700;
        }

        p {
            font-size: var(--text-size-2xs);
            margin-top: 16px;
        }

        button {
            margin-top: 32px;
            padding: 14px 16px;
            width: 100%;
            max-width: 360px;
            border: none;
            background: var(--color-cane);
            border-radius: 65px;
            font-size: var(--text-size-xs);
            font-weight: 400;
        }
    }

    @media screen and (min-width: 1105px) {
        margin-top: -320px;
        padding-top: 394px;

        div.text {
            margin: auto;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;

            h3 {
                font-size: var(--text-size-xlg);
                font-weight: 700;
                width: 578px;
            }

            p {
                font-size: var(--text-size-md);
                margin-top: 32px;
                width: 754px;
                margin: auto;
                margin-top: 32px;
            }

            button {
                margin-top: 48px;
                max-width: 253px;
                height: 64px;
                font-size: var(--text-size-sm);

                &:hover {
                    filter: brightness(1.1);
                }
            }
        }
    }
`

export const InfocardContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 48px;
    align-items: center;
    padding: 0 24px;
    overflow: auto;

    & {
        @media screen and (min-width: 850px) {
            justify-content: center;
        }
    }

    &::-webkit-scrollbar {
        display: none;
    }

    @media screen and (min-width: 1105px) {
        max-width: 1440px;
        margin: 48px auto 104px auto;
        align-items: stretch;
    }
`

export const Infocard = styled.div`
    padding: 24px;
    background: var(--color-gray-100);
    border-radius: 16px;
    width: 265px;
    min-width: 265px;
    margin-bottom: 56px;

    &:not(:last-child) {
        margin-right: 8px;
    }

    svg {
        margin-bottom: 16px;
        width: 43px;
    }

    h3 {
        margin-bottom: 16px;
        font-weight: 700;
        font-size: var(--text-size-xs);
    }

    div {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        h3 {
            margin-bottom: 0;
        }

        div {
            width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            color: var(--color-white);
            background: var(--color-purple);
            font-weight: 700;
            font-size: var(--text-size-xsm);
            margin-bottom: 0;
            margin-right: 16px;
        }
    }

    p {
        font-size: var(--text-size-3xs);
        color: var(--color-gray-500);
    }

    @media screen and (min-width: 1105px) {
        width: 470px;
        padding: 48px;

        h3 {
            font-size: var(--text-size-md);
        }

        p {
            font-size: var(--text-size-sm);
        }

        div {
            font-size: var(--text-size-xsm);
            font-weight: 700;

            h3 {
                font-size: var(--text-size-md);
            }
        }
    }
`

export const SectionScreenGame = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    img  {
        width: 100%;
        max-width: 600px;

        @media screen and (min-width: 1105px) {
            max-width: 900px;
            margin-top: 48px;
        }
    }
`

export const SectionCoupons = styled.div`
    background: var(--color-gray-200);

    div.sectionCouponsImages {
        display: flex;
        justify-content: space-between;
        overflow: hidden;

        img {
            width: 100%;
            max-width: 600px;
        }
    }

    div.sectionCouponsContent {
        padding: 0 46px;
        text-align: center;

        h3 {
            font-weight: 700;
            font-size: var(--text-size-md);
        }

        p {
            color: var(--color-gray-600);
            font-size: var(--text-size-2xs);
            margin-top: 16px;
        }

        button {
            margin-top: 32px;
            padding: 14px 16px;
            width: 100%;
            max-width: 360px;
            border: none;
            background: var(--color-cane);
            border-radius: 65px;
            font-size: var(--text-size-xs);
            font-weight: 400;
        }
    }

    @media screen and (max-width: 1104px) {
        padding-bottom: 56px;
    }

    @media screen and (min-width: 1105px) {
        background: url(${bgLpScreenHome}) no-repeat fixed -120px center/ 450px,
            url(${bgLpScreenGame}) no-repeat fixed right center / 420px
            var(--color-gray-200);
        height: 600px;
        display: flex;
        align-items: center;
        justify-content: center;

        div.sectionCouponsImages {
            display: none;
        }

        div.sectionCouponsContent {
            width: 720px;

            h3 {
                font-size: var(--text-size-xlg);
                width: 630px;
            }

            p {
                font-size: var(--text-size-md);
                margin-top: 32px;
                width: 590px;
            }

            button {
                margin-top: 48px;
                max-width: 308px;
                height: 64px;
                font-size: var(--text-size-sm);

                &:hover {
                    filter: brightness(1.1);
                }
            }
        }
    }
`

export const SectionFaq = styled.div`
    text-align: center;
    padding: 56px 24px;

    h3 {
        margin-bottom: 16px;
        font-size: var(--text-size-md);
        font-weight: 700;
    }

    h5 {
        font-size: var(--text-size-2xs);
        padding: 0 24px;
        font-weight: 400;
    }

    div.faqContainer {
        margin-top: 32px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    @media screen and (min-width: 1105px) {
        width: 900px;
        margin: auto;
        padding: 104px 48px;

        h3 {
            font-size: var(--text-size-xlg);
            margin-bottom: 32px;
        }

        h5 {
            font-size: var(--text-size-md);
        }

        div.faqContainer {
            margin-top: 48px;
        }
    }
`

export const LpForm = styled.div`
    padding: 56px 48px;

    div.title {
        text-align: center;

        h3 {
            font-size: var(--text-size-md);
            font-weight: 700;
        }

        p {
            font-size: var(--text-size-2xs);
            margin-top: 24px;
        }
    }

    form {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;

        div {
            align-items: center;
            width: 100%;

            p {
                margin-top: 0;
            }

            &.checkbox {
                label {
                    width: 100%;

                    div {
                        width: 22px;
                    }

                    a {
                        text-decoration: underline;
                    }
                }
            }
        }

        button {
            margin-top: 32px;
            border: none;
            background: var(--color-cane);
            padding: 14px 16px;
            font-size: var(--text-size-xs);
            width: 100%;
            max-width: 271px;
            border-radius: 65px;
            font-weight: 400;

            &:disabled {
                background: var(--color-gray-400);
                color: var(--color-black);
            }

            @media screen and (min-width: 1105px) {
                height: 64px;
                font-size: var(--text-size-sm);

                &:hover {
                    filter: brightness(1.1);
                }
            }
        }

        div.SelectForm {
            margin-bottom: 32px;

            label {
                color: var(--color-gray-400);
                font-size: var(--text-size-3xs);
            }
            div {
                position: relative;
                display: flex;
                align-items: center;

                svg {
                    position: absolute;
                    ${props => props.right && 'right: 0;'}
                }

                select {
                    padding: 8px 32px;
                    padding-left: 27px;
                    border: none;
                    border-radius: 0;
                    border-bottom: solid 1px var(--color-black);
                    width: 100%;
                    font-size: var(--text-size-xs);
                    background: transparent;
                    color: var(--color-gray-400);

                    &::placeholder {
                        color: var(--color-gray-400);
                        font-size: var(--text-size-xs);
                    }
                }
            }
        }
    }

    @media screen and (min-width: 1105px) {
        display: flex;
        justify-content: center;

        div.lpFormContent{
            width: 600px;

            div.title {
                h1 {
                    font-size: var(--text-size-xlg);
                }

                p {
                    font-size: var(--text-size-md);
                }
            }
        }
    } 
`

export const Breadcrumbs = styled.div`
    margin-top: 85px;
    padding-top: 40px;
    display: flex;
    align-items: center;

    span {
        font-size: var(--text-size-3xs);
        margin: 0 8px;
        color: var(--color-gray-500);
    }

    a {
        font-size: var(--text-size-3xs);
        color: var(--color-gray-500);

        &:last-child {
            color: var(--color-black);
        }
    }
`

export const LpSuccessTitle = styled.div`
    padding: 56px 44px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div.lpSuccesContent {
        h3 {
            font-size: var(--text-size-md);
            font-weight: 700;
        }

        svg {
            margin: 24px 0;
        }

        p {
            color: var(--color-gray-600);
            text-align: center;
        }
    }

    @media screen and (min-width: 1105px) {
        display: flex;
        justify-content: center;

        div.lpSuccesContent {
            width: 708px;

            h3 {
                font-size: var(--text-size-xlg);
                font-weight: 700;
                text-align: center;
            }

            svg {
                margin: 24px 0;
                width: 100%;
            }

            p {
                color: var(--color-gray-600);
                font-size: var(--text-size-md);
                text-align: center;
                width: 574px;
                margin: 0 auto;
            }
        }
    }
`

export const Text = styled.div`
    padding: 40px 0 64px;
    text-align: left;

    h4 {
        font-size: var(--text-size-md);
    }

    h4,
    ul {
        margin-top: 48px;
    }

    li {
        margin-top: 16px;
        margin-left: 16px;
    }

    p {
        margin-top: 24px;
    }

    a {
        text-decoration: underline;
    }

    @media screen and (min-width: 1105px) {
        padding: 80px 0 144px;
    }
`