import styled from "styled-components";

export const Container = styled.div`
    padding: 16px;
    border-radius: 16px;
    background: var(--color-gray-100);
    width: 100%;

    strong {
        color: var(--color-gray-500);
    }

    &:not(:last-child) {
        margin-bottom: 8px;
    }

    div {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        h4 {
            font-size: var(--text-size-xs);
            font-weight: 600;
            text-align: start;
        }

        button {
            background: transparent;
            border: none;
        }
    }    

    p {
        text-transform: none;
        color: var(--color-gray-500);
        margin-top: 8px;
        padding: 0;
        font-size: var(--text-size-2xs);
        text-align: start;
    }

    @media screen and (min-width: 1000px) {
        div {
            cursor: pointer;

            h4 {
                font-size: var(--text-size-sm);
            }
        }

        p {
            font-size: var(--text-size-xs);
        }
    }
`