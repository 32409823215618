export const IconSendPurple = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M40.9377 11.7286C42.16 5.35542 36.5647 -0.239816 30.1916 0.982461L11.8935 4.49175C7.91578 5.2546 4.73469 8.2655 3.72404 12.1778C1.70622 19.989 8.9503 27.1279 16.7285 24.8405C16.9433 24.7773 17.1429 24.9769 17.0797 25.1917C14.7923 32.9699 21.9312 40.214 29.7424 38.1962C33.6548 37.1856 36.6656 34.0044 37.4285 30.0267L40.9377 11.7286ZM30.7566 3.92877C35.0473 3.10587 38.8143 6.87285 37.9914 11.1636L34.4822 29.4617C33.9395 32.2915 31.7909 34.5685 28.9921 35.2916C23.3978 36.7367 18.339 31.543 19.9578 26.0381C20.6909 23.5453 18.3749 21.2293 15.8821 21.9624C10.3773 23.5812 5.18356 18.5224 6.62869 12.9282C7.35171 10.1293 9.62875 7.98076 12.4585 7.43805L30.7566 3.92877Z" fill="#7D08FF" />
            <path d="M5.88909 29.6672C6.47487 29.0814 6.47487 28.1317 5.88909 27.5459C5.3033 26.9601 4.35355 26.9601 3.76777 27.5459L0.93934 30.3743C0.353553 30.9601 0.353553 31.9098 0.93934 32.4956C1.52513 33.0814 2.47487 33.0814 3.06066 32.4956L5.88909 29.6672Z" fill="#7D08FF" />
            <path d="M12.9602 31.0814C13.5459 30.4956 13.5459 29.5459 12.9602 28.9601C12.3744 28.3743 11.4246 28.3743 10.8388 28.9601L8.01041 31.7885C7.42462 32.3743 7.42462 33.3241 8.01041 33.9098C8.59619 34.4956 9.54594 34.4956 10.1317 33.9098L12.9602 31.0814Z" fill="#7D08FF" />
            <path d="M14.3744 38.1525C14.9602 37.5667 14.9602 36.617 14.3744 36.0312C13.7886 35.4454 12.8388 35.4454 12.253 36.0312L9.42462 38.8596C8.83883 39.4454 8.83883 40.3951 9.42462 40.9809C10.0104 41.5667 10.9602 41.5667 11.5459 40.9809L14.3744 38.1525Z" fill="#7D08FF" />
        </svg>

    )
}