export const LogoNameGray = () => {
    return (
        <svg width="90" height="48" viewBox="0 0 163 88" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M43.8793 72.0731C43.8793 63.1857 50.7159 56.4522 59.8377 56.4522C68.8951 56.4522 75.8013 63.1805 75.8013 72.0731C75.8013 80.9657 68.9038 87.6923 59.8377 87.6923C50.7159 87.6853 43.8793 80.9605 43.8793 72.0731ZM69.0188 72.3659C69.1668 66.9637 65.2025 62.7815 59.8412 62.771C54.9641 62.771 50.7333 66.9271 50.6096 71.7978C50.4685 77.1999 54.5043 81.3822 59.8725 81.3735C64.7095 81.3508 68.8846 77.2017 69.0188 72.3589V72.3659Z" fill="#262626" />
            <path d="M107.777 81.6262V86.394C107.777 86.4546 107.753 86.5126 107.711 86.5554C107.668 86.5982 107.61 86.6223 107.549 86.6223H106.076C98.8628 86.6223 94.9472 82.4173 94.9472 75.4887C94.9472 72.5123 95.8042 69.3233 97.5147 66.0785C97.5325 66.0454 97.542 66.0085 97.5424 65.9709C97.5428 65.9333 97.534 65.8962 97.5169 65.8628C97.4997 65.8293 97.4747 65.8006 97.444 65.779C97.4132 65.7573 97.3777 65.7435 97.3405 65.7387L87.4505 64.4474C87.3891 64.4392 87.3269 64.4548 87.2766 64.4912C87.2264 64.5275 87.192 64.5817 87.1805 64.6426L83.0577 86.4254C83.0475 86.4812 83.018 86.5317 82.9744 86.568C82.9308 86.6043 82.8758 86.6241 82.8191 86.624H76.775L81.342 63.1962C81.3505 63.153 81.3458 63.1082 81.3284 63.0678C81.311 63.0273 81.2817 62.9931 81.2445 62.9697C80.5553 62.5004 79.9933 61.8675 79.6088 61.1275C79.2242 60.3876 79.0292 59.5638 79.0411 58.7299C79.0411 56.2327 80.748 54.5249 83.359 54.5249C85.902 54.5249 87.5829 56.0933 87.8267 58.2681C87.8318 58.3186 87.8539 58.3659 87.8893 58.4023C87.9248 58.4387 87.9715 58.462 88.0218 58.4685L106.694 60.6032V62.1402C103.346 66.9132 101.469 71.1739 101.469 74.7498C101.469 78.3849 103.402 80.49 106.638 80.49C106.788 80.4898 106.936 80.519 107.074 80.5759C107.212 80.6329 107.337 80.7165 107.443 80.8221C107.549 80.9276 107.633 81.0529 107.69 81.1908C107.747 81.3288 107.777 81.4767 107.777 81.6262Z" fill="#262626" />
            <path d="M121.79 63.4471C121.723 63.4476 121.659 63.4745 121.611 63.5221C121.563 63.5697 121.537 63.6342 121.536 63.7015V76.6823C121.536 79.2388 123.128 80.7705 125.795 80.7705H128.683V86.6083H124.887C118.58 86.6083 115.179 83.4298 115.179 77.6896V63.4541H110.301V57.6581H113.242C113.845 57.6581 114.424 57.4182 114.851 56.991C115.278 56.5639 115.518 55.9846 115.518 55.3805V49.818H121.526V57.3985C121.526 57.4656 121.553 57.5299 121.601 57.5772C121.648 57.6246 121.713 57.6511 121.78 57.6511H128.672V63.4349L121.79 63.4471Z" fill="#262626" />
            <path d="M162.829 74.5807H138.866C139.777 78.7822 143.239 81.5669 147.78 81.5669C150.741 81.5669 153.34 80.3732 154.922 78.5696C155.138 78.3302 155.402 78.1395 155.697 78.0101C155.992 77.8806 156.312 77.8153 156.634 77.8185H162.542C160.212 83.4994 154.53 87.3071 147.664 87.3071C138.69 87.3071 132.05 80.7705 132.05 72.1462C132.05 63.522 138.69 56.975 147.493 56.975C156.35 56.975 162.995 63.5098 162.995 72.202C162.995 72.9978 162.939 73.7926 162.829 74.5807ZM138.927 69.3528H155.195C155.305 69.3534 155.413 69.3277 155.51 69.2778C155.607 69.2279 155.691 69.1553 155.755 69.0662C155.819 68.977 155.86 68.8738 155.875 68.7654C155.89 68.657 155.879 68.5465 155.843 68.4432C154.566 64.9736 151.368 62.7065 147.495 62.7065C143.295 62.7082 140.003 65.3762 138.927 69.3546V69.3528Z" fill="#262626" />
            <path d="M163 8.61723C163 8.55668 162.975 8.49862 162.933 8.45581C162.89 8.413 162.832 8.38894 162.771 8.38894H156.675C156.665 8.38894 156.656 8.3928 156.649 8.39966C156.642 8.40653 156.638 8.41584 156.638 8.42554V11.5884C156.639 11.6807 156.611 11.771 156.559 11.8472C156.507 11.9235 156.433 11.9822 156.347 12.0156C156.261 12.049 156.167 12.0556 156.077 12.0343C155.987 12.0131 155.906 11.9652 155.844 11.8968C154.674 10.5672 153.231 9.50546 151.613 8.78377C149.996 8.06207 148.242 7.6974 146.472 7.71455C138.297 7.71455 132.224 14.2459 132.224 22.8754C132.224 31.5048 138.297 38.0362 146.472 38.0362C148.271 38.0531 150.052 37.6771 151.691 36.9342C153.33 36.1914 154.787 35.0997 155.961 33.7354C156.057 33.623 156.179 33.5359 156.316 33.4815C156.454 33.4272 156.602 33.4073 156.749 33.4236C156.896 33.44 157.037 33.492 157.159 33.5752C157.281 33.6584 157.381 33.7702 157.45 33.9009C158.406 35.7516 160.22 36.846 162.74 37.1858C162.772 37.1897 162.805 37.1867 162.836 37.1771C162.868 37.1674 162.896 37.1513 162.921 37.1298C162.945 37.1082 162.965 37.0817 162.979 37.052C162.992 37.0224 162.999 36.9901 163 36.9575V8.61723ZM147.724 31.9109C142.559 31.9109 138.754 27.9882 138.754 22.8771C138.754 17.766 142.559 13.8434 147.724 13.8434C152.836 13.8434 156.642 17.766 156.642 22.8771C156.642 27.9882 152.836 31.9109 147.724 31.9109Z" fill="#262626" />
            <path d="M125.675 28.5581V0.0365989C125.675 0.0317932 125.674 0.0270311 125.672 0.0225911C125.67 0.0181512 125.668 0.0141194 125.664 0.0107212C125.661 0.00732305 125.657 0.00462574 125.652 0.00278666C125.648 0.00094759 125.643 0 125.638 0H119.354C119.344 0.000425897 119.335 0.00442706 119.328 0.0111998C119.322 0.0179726 119.318 0.0270302 119.317 0.0365989V11.5919C119.318 11.6841 119.29 11.7743 119.238 11.8505C119.186 11.9267 119.113 11.9853 119.027 12.0186C118.941 12.0518 118.847 12.0582 118.757 12.0368C118.667 12.0153 118.586 11.9672 118.525 11.8986C117.354 10.5689 115.911 9.50705 114.293 8.78535C112.676 8.06366 110.921 7.69905 109.15 7.71634C100.976 7.71634 94.9024 14.2477 94.9024 22.8772C94.9024 31.5066 100.976 38.038 109.15 38.038C110.949 38.0537 112.729 37.6768 114.366 36.9338C116.004 36.1907 117.46 35.0992 118.633 33.7354C118.729 33.623 118.851 33.536 118.989 33.4821C119.127 33.4281 119.276 33.4089 119.423 33.4261C119.57 33.4434 119.71 33.4965 119.832 33.5808C119.954 33.6651 120.053 33.7781 120.12 33.9097C121.259 36.1751 123.604 37.3723 127.039 37.3723H128.627C128.657 37.3725 128.687 37.3668 128.714 37.3556C128.742 37.3443 128.767 37.3276 128.788 37.3066C128.81 37.2855 128.827 37.2605 128.838 37.2329C128.85 37.2053 128.855 37.1757 128.855 37.1458V31.2261H128.343C126.642 31.2261 125.675 30.2031 125.675 28.5581ZM110.399 31.9109C105.235 31.9109 101.431 27.9883 101.431 22.8772C101.431 18.24 104.566 14.5823 108.999 13.9427C115.116 13.061 119.869 17.6963 119.276 23.853C118.821 28.4797 115.182 31.9109 110.399 31.9109Z" fill="#262626" />
            <path d="M65.7182 4.20147L69.4979 0.0609982C69.5026 0.0558053 69.5057 0.0493915 69.5068 0.0425126C69.5079 0.0356338 69.5071 0.028573 69.5043 0.0221657C69.5016 0.0157584 69.4971 0.0102653 69.4913 0.00633913C69.4855 0.00241294 69.4788 0.000213658 69.4718 0H62.0082C62.0026 0.000198032 61.9972 0.00172794 61.9924 0.00445654C61.9875 0.00718514 61.9834 0.0110378 61.9803 0.0156883L58.3504 4.92291C58.3466 4.92837 58.3444 4.93477 58.3439 4.94141C58.3435 4.94805 58.3449 4.95467 58.3479 4.96059C58.351 4.9665 58.3556 4.97147 58.3612 4.97497C58.3669 4.97846 58.3734 4.98035 58.38 4.98042H63.9538C64.2858 4.98014 64.6142 4.91074 64.9179 4.77663C65.2217 4.64253 65.4942 4.44666 65.7182 4.20147Z" fill="#262626" />
            <path d="M62.0573 7.70933C53.2542 7.70933 46.611 14.2407 46.611 22.8702C46.611 31.4996 53.2542 38.031 62.2228 38.031C69.0733 38.031 74.7411 34.246 77.0804 28.5947C77.0826 28.5892 77.0835 28.5832 77.083 28.5773C77.0824 28.5713 77.0804 28.5656 77.0772 28.5607C77.0739 28.5557 77.0695 28.5516 77.0643 28.5487C77.0591 28.5458 77.0532 28.5443 77.0473 28.5441H71.1966C70.8731 28.5405 70.5527 28.606 70.2565 28.7361C69.9604 28.8662 69.6953 29.058 69.4791 29.2987C67.8941 31.1006 65.2988 32.2943 62.3377 32.2943C57.8091 32.2943 54.3551 29.5287 53.4319 25.3499C53.4309 25.3445 53.4311 25.3389 53.4324 25.3335C53.4338 25.3281 53.4363 25.3231 53.4398 25.3188C53.4432 25.3145 53.4476 25.311 53.4526 25.3086C53.4575 25.3061 53.463 25.3048 53.4685 25.3046H77.3573C77.3656 25.3051 77.3737 25.3026 77.3801 25.2974C77.3866 25.2922 77.3909 25.2849 77.3922 25.2767C77.4976 24.4952 77.5517 23.7076 77.5541 22.9189C77.5541 14.2407 70.9109 7.70933 62.0573 7.70933ZM53.5225 20.0924C53.5168 20.0923 53.5112 20.0909 53.506 20.0884C53.5009 20.0858 53.4965 20.0821 53.493 20.0776C53.4895 20.0731 53.487 20.0679 53.4858 20.0623C53.4846 20.0567 53.4846 20.0509 53.4859 20.0454C54.5763 16.0983 57.8648 13.4443 62.0503 13.4443C65.9206 13.4443 69.1186 15.7184 70.3953 19.195C70.4313 19.2979 70.442 19.408 70.4266 19.516C70.4112 19.624 70.3702 19.7267 70.3068 19.8155C70.2435 19.9043 70.1598 19.9766 70.0628 20.0263C69.9657 20.076 69.8581 20.1017 69.7491 20.1011L53.5225 20.0924Z" fill="#262626" />
            <path d="M42.3381 31.6915V38.0085C42.3381 38.0182 42.3343 38.0275 42.3274 38.0343C42.3206 38.0412 42.3113 38.0451 42.3016 38.0451H17.6585C17.6489 38.0446 17.6399 38.0406 17.6331 38.0339C17.6263 38.0271 17.6224 38.018 17.6219 38.0085V33.265C17.6195 33.2577 17.6195 33.2498 17.6219 33.2424L32.0319 15.1069C32.0909 15.0302 32.1275 14.9386 32.1376 14.8423C32.1477 14.746 32.1308 14.6488 32.0889 14.5616C32.047 14.4743 31.9817 14.4004 31.9003 14.3481C31.8189 14.2957 31.7245 14.2671 31.6278 14.2652H20.1319C19.5313 14.2652 18.9554 14.0266 18.5307 13.6017C18.1061 13.1769 17.8675 12.6007 17.8675 11.9998V7.91338C17.8675 7.90367 17.8714 7.89436 17.8782 7.8875C17.8851 7.88063 17.8944 7.87678 17.9041 7.87678H41.8887C41.8981 7.87722 41.907 7.88128 41.9135 7.88809C41.92 7.89491 41.9236 7.90396 41.9236 7.91338V12.6498C41.9261 12.6572 41.9261 12.6651 41.9236 12.6725L27.5206 30.8149C27.462 30.8917 27.4258 30.9833 27.4161 31.0795C27.4063 31.1757 27.4234 31.2727 27.4655 31.3597C27.5075 31.4467 27.5729 31.5204 27.6542 31.5725C27.7356 31.6246 27.8298 31.6531 27.9264 31.6549H42.3085C42.317 31.6565 42.3246 31.6611 42.33 31.6678C42.3354 31.6745 42.3383 31.6828 42.3381 31.6915Z" fill="#262626" />
            <path d="M28.5499 57.1475H22.2446C21.893 57.1476 21.549 57.2492 21.2539 57.4403C20.9588 57.6313 20.7252 57.9037 20.5811 58.2245L7.81371 86.6658C7.79865 86.7 7.79235 86.7374 7.79539 86.7747C7.79843 86.812 7.81071 86.8479 7.83111 86.8792C7.85152 86.9105 7.87941 86.9362 7.91226 86.9541C7.94511 86.9719 7.98188 86.9812 8.01924 86.9812H12.3964C13.0103 86.9814 13.6112 86.804 14.1266 86.4704C14.6421 86.1368 15.0502 85.6612 15.3017 85.1009L15.5195 84.613L16.5036 82.419C19.1947 85.6359 23.5178 87.6852 28.4872 87.6852C36.0902 87.6852 40.7077 82.8286 40.7077 75.6333C40.7077 65.0451 30.3579 64.9876 28.5499 57.1475ZM27.2592 81.37C23.7495 81.37 20.5358 79.6152 18.8411 77.0396L24.6883 63.82C27.6685 69.203 33.9861 70.4891 33.9861 75.52C33.9861 79.0854 31.234 81.3665 27.2592 81.3665V81.37Z" fill="#262626" />
            <path d="M10.0035 66.0681C10.7839 65.7945 11.4161 65.127 11.8882 64.0553C12.156 63.4173 12.3059 62.7359 12.3306 62.0443C12.3727 61.2743 12.3266 60.5019 12.193 59.7423C12.0673 58.9953 11.8809 58.2598 11.6356 57.5431C11.4364 56.9405 11.1827 56.3572 10.8779 55.8005L10.7159 55.858C10.9981 56.9646 10.9789 57.7749 10.6637 58.2977C10.3394 58.823 9.89147 59.261 9.35907 59.5733C8.8075 59.9032 8.23154 60.214 7.6312 60.5056C7.06589 60.7658 6.61455 61.2229 6.36143 61.7917C5.92249 62.7919 5.87546 63.6632 6.2186 64.4178C6.51809 65.1185 7.06761 65.6826 7.76009 66.0001C8.47002 66.3164 9.27578 66.3408 10.0035 66.0681Z" fill="#262626" />
            <path d="M5.83677 73.8315C5.63072 73.7381 5.40814 73.6868 5.18205 73.6805C4.95595 73.6742 4.73087 73.7131 4.51996 73.7949L4.42242 73.8315L4.38584 73.7339C4.30521 73.5225 4.18377 73.3291 4.02844 73.1648C3.87311 73.0004 3.68694 72.8682 3.48055 72.7758C3.27417 72.6834 3.05162 72.6326 2.82561 72.6263C2.59959 72.6199 2.37454 72.6582 2.1633 72.7388C1.95206 72.8195 1.75877 72.941 1.59447 73.0964C1.43017 73.2518 1.29807 73.4381 1.20571 73.6445C1.11336 73.851 1.06256 74.0737 1.05621 74.2998C1.04987 74.5259 1.0881 74.7511 1.16873 74.9624L1.20531 75.06L1.10777 75.0966C0.896528 75.1773 0.703239 75.2988 0.538936 75.4542C0.374632 75.6096 0.242532 75.7958 0.150178 76.0023C0.0578236 76.2088 0.00702348 76.4314 0.000678175 76.6576C-0.00566714 76.8837 0.0325666 77.1088 0.113196 77.3202C0.193826 77.5315 0.315273 77.7249 0.470603 77.8893C0.625933 78.0537 0.812103 78.1858 1.01849 78.2782C1.22487 78.3706 1.44742 78.4214 1.67343 78.4278C1.89945 78.4341 2.1245 78.3959 2.33574 78.3152L2.43328 78.2769L2.46986 78.3745C2.54525 78.592 2.66348 78.7922 2.81755 78.9632C2.97163 79.1342 3.15842 79.2725 3.36688 79.37C3.57533 79.4676 3.80122 79.5223 4.03117 79.5309C4.26113 79.5395 4.49048 79.502 4.70566 79.4204C4.92084 79.3388 5.11748 79.2148 5.28395 79.0559C5.45042 78.8969 5.58333 78.7062 5.67482 78.4949C5.76631 78.2837 5.81453 78.0562 5.81662 77.826C5.8187 77.5958 5.77462 77.3675 5.68697 77.1546L5.65039 77.057L5.74619 77.0205C6.06431 76.8992 6.33933 76.6863 6.53648 76.4086C6.73362 76.131 6.84402 75.801 6.85371 75.4606C6.86341 75.1201 6.77197 74.7845 6.59095 74.496C6.40993 74.2076 6.14747 73.9794 5.83677 73.8402V73.8315Z" fill="#262626" />
        </svg>

    )
}