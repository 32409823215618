export const IconAndroid = () => {
    return (
        <svg width="64" height="56" viewBox="0 0 64 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.82202 48.2156C3.16832 48.2156 2.05554 49.513 2.05554 51.1811C2.05554 52.8492 3.16832 54.1466 4.82202 54.1466C6.47573 54.1466 7.5885 52.8492 7.5885 51.1811C7.5885 49.513 6.47573 48.2156 4.82202 48.2156ZM4.4511 46.3776C5.78024 46.3776 6.89302 47.0572 7.4185 47.7986V46.5629H9.45858V55.8147H7.89761C7.63487 55.8147 7.43395 55.5984 7.43395 55.3513V54.579C6.90848 55.3204 5.81115 56 4.46655 56C1.9319 56 0 53.8377 0 51.1965C0 48.5554 1.91644 46.3776 4.4511 46.3776Z" fill="#262626" />
            <path d="M11.7769 46.5629H13.817V47.814C14.3733 46.8873 15.4243 46.3776 16.6298 46.3776C18.7781 46.3776 20.169 47.8912 20.169 50.2389V55.8147H18.6081C18.3453 55.8147 18.1444 55.5984 18.1444 55.3513V50.5787C18.1444 49.1114 17.4026 48.231 16.2125 48.231C14.8679 48.231 13.817 49.2813 13.817 51.2583V55.8147H12.256C11.9932 55.8147 11.7923 55.5984 11.7923 55.3513V46.5629H11.7769Z" fill="#262626" />
            <path d="M26.5984 48.2156C24.9447 48.2156 23.8319 49.513 23.8319 51.1811C23.8319 52.8492 24.9447 54.1466 26.5984 54.1466C28.2521 54.1466 29.3649 52.8492 29.3649 51.1811C29.3649 49.513 28.2521 48.2156 26.5984 48.2156ZM26.2275 46.3776C27.5566 46.3776 28.6694 47.0572 29.1949 47.7986V41.9294H31.235V55.8147H29.674C29.4113 55.8147 29.2103 55.5984 29.2103 55.3513V54.579C28.6849 55.3204 27.5875 56 26.2429 56C23.7083 56 21.7764 53.8377 21.7764 51.1965C21.7764 48.5554 23.6928 46.3776 26.2275 46.3776Z" fill="#262626" />
            <path d="M33.5532 46.5629H35.5933V48.2156C35.9488 47.1653 36.9843 46.4703 38.0353 46.4703C38.298 46.4703 38.5607 46.4857 38.8235 46.5475V48.6326C38.468 48.5245 38.1744 48.4627 37.8034 48.4627C36.6443 48.4627 35.5779 49.4512 35.5779 51.2892V55.7992H34.0169C33.7542 55.7992 33.5532 55.583 33.5532 55.3359V46.5629Z" fill="#262626" />
            <path d="M44.3101 54.1002C45.9329 54.1002 47.092 52.8183 47.092 51.1811C47.092 49.5284 45.9483 48.2619 44.3101 48.2619C42.6564 48.2619 41.5127 49.5439 41.5127 51.1811C41.5281 52.8337 42.6718 54.1002 44.3101 54.1002ZM44.3101 56C41.5745 56 39.4571 53.8686 39.4571 51.1965C39.4571 48.509 41.5745 46.3776 44.3101 46.3776C47.0457 46.3776 49.163 48.509 49.163 51.1965C49.163 53.8686 47.0611 56 44.3101 56Z" fill="#262626" />
            <path d="M50.8167 46.5629H52.8568V55.8147H51.2958C51.0331 55.8147 50.8322 55.5984 50.8322 55.3513V46.5629H50.8167ZM51.8367 44.5396C51.0949 44.5396 50.4612 43.9218 50.4612 43.165C50.4612 42.4236 51.0949 41.8212 51.8367 41.8212C52.5941 41.8212 53.1814 42.4391 53.1814 43.165C53.1814 43.9218 52.5941 44.5396 51.8367 44.5396Z" fill="#262626" />
            <path d="M59.3634 48.2156C57.7097 48.2156 56.597 49.513 56.597 51.1811C56.597 52.8492 57.7097 54.1466 59.3634 54.1466C61.0171 54.1466 62.1299 52.8492 62.1299 51.1811C62.1145 49.513 61.0171 48.2156 59.3634 48.2156ZM58.9925 46.3776C60.3217 46.3776 61.4344 47.0572 61.9599 47.7986V41.9294H64V55.8147H62.439C62.1763 55.8147 61.9754 55.5984 61.9754 55.3513V54.579C61.4499 55.3204 60.3526 56 59.008 56C56.4733 56 54.5414 53.8377 54.5414 51.1965C54.5414 48.5554 56.4579 46.3776 58.9925 46.3776Z" fill="#262626" />
            <path d="M47.6793 10.8844L53.0114 1.66353C53.3205 1.13839 53.135 0.458802 52.6095 0.149896C52.084 -0.159009 51.404 0.0263346 51.0949 0.551475L45.701 9.88042C41.5745 7.9961 36.9379 6.94582 32.0077 6.94582C27.0621 6.94582 22.441 7.9961 18.3144 9.88042L12.9206 0.551475C12.6115 0.0263346 11.9314 -0.159009 11.4059 0.149896C10.8805 0.458802 10.695 1.13839 11.0041 1.66353L16.3362 10.8844C7.15576 15.8577 0.911858 25.1095 0 36.0293H63.9846C63.0727 25.1095 56.8133 15.8577 47.6793 10.8844ZM17.2635 26.9166C15.7952 26.9166 14.6052 25.7273 14.6052 24.26C14.6052 22.7927 15.7952 21.6034 17.2635 21.6034C18.7317 21.6034 19.9218 22.7927 19.9218 24.26C19.9218 25.7273 18.7317 26.9166 17.2635 26.9166ZM46.7211 26.9166C45.2528 26.9166 44.0628 25.7273 44.0628 24.26C44.0628 22.7927 45.2528 21.6034 46.7211 21.6034C48.1893 21.6034 49.3794 22.7927 49.3794 24.26C49.3794 25.7273 48.1893 26.9166 46.7211 26.9166Z" fill="#262626" />
        </svg>
    )
}