import styled from "styled-components";
import defaultContentBanner from "../../assets/images/default-content-banner.jpeg";

export const Card = styled.div`
  min-width: 279px;
  max-width: 480px;

  .imageContainer {
    height: 148px;
    background: url(${defaultContentBanner}) no-repeat center center / cover;
    border-radius: 12px;
  }

  h4 {
    font-size: var(--text-size-xsm);
    line-height: 22px;
    margin: 16px 0;
    font-weight: 600;
  }

  p {
    color: var(--color-gray-500);
    font-size: var(--text-size-2xs);
    line-height: 18px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  a {
    display: inline-block;
    font-size: var(--text-size-2xs);
    padding: 15px 0;
    margin-top: 16px;
    color: var(--color-purple-600);

    &:hover {
      text-decoration: underline;
    }
  }

  @media screen and (min-width: 1105px) {
    max-width: 924px;

    h4 {
      font-size: var(--text-size-lg);
      line-height: 36px;
      margin: 24px 0;
    }
    p {
      font-size: var(--text-size-sm);
      line-height: 24px;
    }
    a {
      font-size: var(--text-size-sm);
      padding: 20px 0;
      margin-top: 24px;
    }
  }
`