import {
  BlackButton,
  GreenButton,
  SectionCoupons,
  SectionFaq,
  SectionInfo,
  SectionScreenGame,
  SectionScreenHome,
  SectionTitle,
} from "./styles";
import lpScreenHomeMobile from "../../assets/images/lp-screen-home-mobile.webp";
import lpScreenHomeDesktop from "../../assets/images/lp-screen-home-desktop.webp";
import lpScreenGameMobile from "../../assets/images/lp-screen-game-mobile.webp";
import lpScreenGameDesktop from "../../assets/images/lp-screen-game-desktop.webp";
import { Infocard, InfocardContainer, SectionContents } from "./styles";
import { IconSmilePurple } from "../../assets/icons/IconSmilePurple";
import { IconLockPurple } from "../../assets/icons/IconLockPurple";
import { IconStarPurple } from "../../assets/icons/IconStarPurple";
import { IconWalletPurple } from "../../assets/icons/IconWalletPurple";
import { LpFaq } from "../../components/LpFaq";
import { LpVideo } from "../../components/LpVideo";
import { IconPlay } from "../../assets/icons/IconPlay";
// import { IconScroll } from "../../assets/icons/IconScroll";
import { addCTAEvent } from "../../utils/dataLayer";
// import { ConfirmAge } from "../../components/ConfirmAge";
import { IconUserPurple } from "./../../assets/icons/IconUserPurple";
import { IconCloverPurple } from "./../../assets/icons/IconCloverWhitePurple";
import { IconSendPurple } from "./../../assets/icons/IconSendPurple";
import { IconTicketPurple } from "../../assets/icons/IconTicketPurple";
import { Link } from "react-router-dom";
import LpContentCard from "./../../components/LpContent/index";
import { contentsText } from "../../utils/contentsText";
import { Coupon } from "../../components/Modal/Coupon";
import { useState } from "react";

export const Index = ({ showVideo, setShowVideo }) => {
  // const [showConfirmAgeModal, setShowConfirmAgeModal] = useState(false);

  // useEffect(() => {
  //   if (!sessionStorage.getItem("confirm-age")) setShowConfirmAgeModal(true);
  // }, []);
  function handleCTAButtonClick() {
    addCTAEvent();
    window.open("http://www.app.zedasorte.com.br");
  }

  const [showCouponModal, setShowCouponModal] = useState(true);

  return (
    <>
      {/* {showConfirmAgeModal && <ConfirmAge setView={setShowConfirmAgeModal} />} */}
      {showVideo && <LpVideo setView={setShowVideo} />}

      {showCouponModal && <Coupon setView={() => setShowCouponModal(false)} />}

      <SectionTitle>
        <div>
          <div id="hero-text">
            {/* &nbsp; é o caractere de um espaço em branco */}
            <h2>
              _aposte nas loterias oficiais <span className="space">&nbsp;&nbsp;</span>sem precisar
              ir até a lotérica
              <span className="emphasis">.</span>
            </h2>
            <GreenButton onClick={handleCTAButtonClick}>quero jogar</GreenButton>
          </div>
          <div className="buttons">
            <p>Conhecer</p>
            <button className="videoButton" onClick={() => setShowVideo(true)}>
              <IconPlay />
            </button>
          </div>
        </div>
      </SectionTitle>

      <SectionScreenHome>
        <picture>
          <source media="(min-width: 1105px)" srcSet={lpScreenHomeDesktop} />
          <source media="(max-width: 1099px)" srcSet={lpScreenHomeMobile} />
          <img loading="lazy" src={lpScreenHomeMobile} alt="" />
        </picture>
      </SectionScreenHome>

      <SectionInfo id="info">
        <div className="text">
          <h3>Praticidade e segurança em um só lugar</h3>
          <p>
            no zé da sorte você joga nas loterias oficiais a qualquer momento e em qualquer lugar
          </p>
        </div>
        <div className="buttonCenter">
          <GreenButton className="marginBottom" onClick={handleCTAButtonClick}>
            fazer fezinha agora
          </GreenButton>
        </div>
        <div className="wrapper withLineBrake">
          <InfocardContainer>
            <Infocard>
              <IconUserPurple />
              <h3>crie seu cadastro</h3>
              <p>cadastre seus dados e valide sua conta</p>
            </Infocard>
            <Infocard>
              <IconCloverPurple />
              <h3>faça sua fezinha</h3>
              <p>escolha seu jogo e os números da sorte</p>
            </Infocard>
            <Infocard>
              <IconSendPurple />
              <h3>aguarde o registro</h3>
              <p>registramos seu jogo em loterias oficiais</p>
            </Infocard>
            <Infocard>
              <IconTicketPurple />
              <h3>confira o jogo</h3>
              <p>sua fezinha direto na plataforma</p>
            </Infocard>
          </InfocardContainer>
        </div>
        <div className="text" id="platform">
          <h4>a plataforma que vai mudar seu jeito de jogar</h4>
          <p>
            faça sua fezinha em jogos das loterias oficiais a qualquer momento e em qualquer lugar
          </p>
        </div>
        <SectionScreenGame>
          <picture>
            <source srcSet={lpScreenGameDesktop} media="(min-width: 1105px)" />
            <img loading="lazy" src={lpScreenGameMobile} alt="" />
          </picture>
        </SectionScreenGame>
        <div className="wrapper">
          <InfocardContainer>
            <Infocard>
              <IconSmilePurple />
              <h3>experiência amigável</h3>
              <p>plataforma muito fácil de usar e acessível para todas as pessoas</p>
            </Infocard>
            <Infocard>
              <IconLockPurple />
              <h3>totalmente seguro</h3>
              <p>tecnologia que garante o registro dos jogos em lotéricas oficiais</p>
            </Infocard>
            <Infocard>
              <IconStarPurple />
              <h3>comodidade</h3>
              <p>jogue quando e onde quiser: 24h por dia, 7 dias na semana</p>
            </Infocard>
            <Infocard>
              <IconWalletPurple />
              <h3>prêmio na conta</h3>
              <p>receba o prêmio em sua conta em até 5 dias úteis</p>
            </Infocard>
          </InfocardContainer>
        </div>
      </SectionInfo>

      <SectionContents>
        <div>
          <h3>dicas do zé para aumentar suas chances de ganhar</h3>
          <div>
            <LpContentCard
              content={contentsText.get("desvendando-os-numeros-mais-sorteados-na-mega-sena")}
            />
            <div className="line" />
            <LpContentCard content={contentsText.get("descubra-as-vantagens-da-dupla-sena")} />
          </div>
        </div>
      </SectionContents>

      <SectionCoupons>
        {/* <div className="sectionCouponsImages">
          <img loading="lazy" src={bgScreenHome} alt="" />
        </div> */}
        <div className="sectionCouponsContent">
          <h3>a melhor experiência em jogos online de loterias </h3>
          <p>com o zé sua fezinha é 100% segura e rápida</p>
          <GreenButton onClick={handleCTAButtonClick}>jogar agora</GreenButton>
        </div>
      </SectionCoupons>
      <SectionFaq id="faq">
        <h3>Perguntas frequentes</h3>
        <h5>Tire aqui suas dúvidas ou entre em contato com o Zé</h5>
        <div className="faqContainer">
          <LpFaq title="o zé da sorte é seguro?">
            <p>
              Sim. Somos muito seguros. Registramos suas fezinhas em lotéricas oficiais da Caixa. Na
              plataforma você acessa o comprovante de pagamento e registro de jogo. Além disso, não
              divulgamos seus dados e utilizamos o Mercado Pago para pagamento dos jogos.
            </p>
          </LpFaq>
          <LpFaq title="quais as vantagens de jogar pelo zé da sorte?">
            <p>
              <strong>Praticidade</strong>: Com o Zé da Sorte você joga online e não precisa ir na
              lotérica fazer sua fezinha.
              <br />
              <strong>Comodidade</strong>: Jogue de onde estiver e quando quiser.
              <br />
              <strong>Rapidez</strong>: Sua fezinha em poucos minutos: escolha o jogo, as dezenas, a
              forma de pagamento, e pronto. Agora é só torcer.
              <br />
              <strong>Segurança</strong>: Todos os jogos são conferidos e registrados em loterias
              oficiais da Caixa. Além disso, utilizamos a plataforma do Mercado Pago para você pagar
              os jogos.
            </p>
          </LpFaq>
          <LpFaq title="tem um valor mínimo para jogar?">
            <p>
              Não. No Zé da Sorte você paga apenas o valor que jogar e faz quantas fezinhas quiser.{" "}
            </p>
          </LpFaq>
          <LpFaq title="existe um horário limite para jogar?">
            <p>
              Não. Com o Zé da Sorte você joga qualquer dia, em qualquer horário. Em dias de
              concurso, se você jogar até as 16h (horário de Brasília), os jogos serão transmitidos
              para a lotérica no mesmo dia; após esse horário, os jogos serão válidos para o
              concurso seguinte.
            </p>
          </LpFaq>
          <LpFaq title="como faço meu cadastro?">
            <p>
              Acesse a plataforma e clique em criar conta. Insira seus dados e fique de olho em seus
              e-mails para ativar seu cadastro.
            </p>
          </LpFaq>
          <LpFaq title="como vou saber se ganhei?">
            <p>
              Após o concurso enviamos os resultados por email. Você também pode acessar o site e ir
              em "resultados"
            </p>
          </LpFaq>
          <LpFaq title="qualquer pessoa pode jogar?">
            <p>
              Somente pessoas com idade acima de 18 anos, com CPF e em território nacional podem
              jogar.
            </p>
          </LpFaq>
        </div>
        <Link to="./perguntas-frequentes">
          <BlackButton className="marginTop">Conferir mais perguntas</BlackButton>
        </Link>
      </SectionFaq>
    </>
  );
};
