import { contentsLink } from "../LpFooter";
import { Card } from "./styles";
import { Link } from "react-router-dom";

export default function LpContentCard({ content, className }) {
  return (
    <Card className={className}>
      {/* <div className="imageContainer" /> */}
      <h4>{content.title}</h4>

      <p>{content.description}</p>
      <Link to={contentsLink+content.link}>conferir conteúdo</Link>
    </Card>
  );
}
