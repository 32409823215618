import styled from "styled-components";

export const Container = styled.div`
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    background-color: rgba(0,0,0,0.5);
    z-index: 10;
    height: 100vh;
`

export const Close = styled.div`
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        border: none;
        background-color: transparent;
    }
`

export const Content = styled.div`
    max-width: 628px;

    div {
        background-color: var(--color-white);
        padding: 24px;
        border-radius: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;

        img {
            max-width: 580px;
        }

        button {
            border: none;
            background-color: var(--color-black);
            color: var(--color-white);
            font-size: var(--text-size-xs);
            padding: 14px;
            border-radius: 65px;
        }

        p {
            color: var(--color-gray-500);
            font-size: var(--text-size-3xs);
            text-align: center;
        }

        @media screen and (max-width: 999px) {
            padding: 16px;
            max-width: 328px;

            img {
                max-width: 296px;
            }
        }
    }
`