import styled from "styled-components";

export const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 2;

    div.background {
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        background: rgba(0,0,0,0.5);
        position: absolute;
        z-index: -1;
    }

    div.videoContent {
        display: contents;

        iframe,
        video {
            border-radius: 16px;

            @media screen and (min-width: 770px) {
                max-width: 65%;
            }

            @media screen and (max-width: 769px) {
                width: 90%;
            }
        }
    }
`