import styled from "styled-components";

export const Container = styled.div`

    div.content {
        display: none;
    }

    div.footerContainer {
        background: var(--color-black);
        flex-basis: 100%;

        > div {
                width: 100%;
                max-width: 740px;
            }
    }

    @media screen and (min-width: 1105px) {
        display: flex;
        align-items: stretch;
    
        div.footerContainer, div.content {
            /* para achar o flex-basis dividi o grafismo em colunas no figma até chegar no valor 37, contei 15 colunas até chegar no limite da parte branca do content, logo sobraram 22 colunas e a porcentagem equivalente a 37/22 é aproximadamente 59,5 */
            flex-basis: 59.5%;
            background: var(--color-black);
            display: flex;
            flex-direction: column;
            width: 100vw;
            padding: 0 24px;

            div.infoContainer {
                /* display: flex; */
                /* flex-direction: column; */
                /* align-items: center; */

                div.footerLogo {
                    display: flex;
                    flex: 1;
                    justify-content: space-between;
                    align-items: center;

                    p {
                        margin-top: 0;
                        margin-right: 24px;
                    }
                }
            }
        }

        div.content {
            background-color: var(--color-gray-200);
            flex-basis: 40.5%;
            
            > div {
                max-width: 412px;
            }

            > div > {
                h1 {
                    margin-top: 24px;
                }

                p {
                    color: var(--color-gray-500);
                    font-size: var(--text-size-sm);
                    line-height: 24px;
                    margin: 24px 0;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                }

                a {
                    display: inline-block;
                    font-size: var(--text-size-sm);
                    color: var(--color-purple-600);
                    padding: 20px 0;
                }
            }
        }
    }
`

export const Breadcrumbs = styled.div`
    
    display: flex;
    align-items: center;

    span {
        font-size: var(--text-size-3xs);
        margin: 0 8px;
        color: var(--color-gray-500);
    }

    a {
        font-size: var(--text-size-3xs);
        color: var(--color-gray-500);

        &:last-child {
            color: var(--color-black);
        }
    }
`

export const SectionFooter = styled.div`
    padding: 64px 24px;

    div.line {
            margin-top: 24px;
            width: 56px;
            border-top: solid 1px var(--color-gray-600);
        }
    a:hover {
        text-decoration: underline;
    }

    h1 {
        font-size: clamp(var(--text-size-sm), calc(10vw - var(--text-size-sm)) ,var(--text-size-lg));
        line-height: 36px;
        font-weight: 600;
        margin-bottom: 24px;

        a {
            color: var(--color-cane);
        }  
    }

    div.footerLinksContainer {
        display: flex;
        flex-direction: column;

        &:not(:first-child) {
            margin-top: 24px;
        }

        p {
            color: var(--color-white);
            font-size: var(--text-size-sm);
            font-weight: 600;
        }

        a {
            margin-top: 8px;
            color: var(--color-gray-400);
            font-size: var(--text-size-xs);
        }
    }

    
    
    div.newsletter {
        margin-top: 24px;

        input {
            border: none;
            background: var(--color-gray-700);
            border-radius: 65px;
            width: 100%;
            padding: 12px 14px;
            color: var(--color-gray-100);
            margin-bottom: 16px;
            font-size: var(--text-size-xs);
            opacity: 0.5;

            &::placeholder {
                color: var(--color-gray-100);
            }
        }

        button {
            border: none;
            background: var(--color-cane);
            border-radius: 65px;
            padding: 12px 14px;
            width: 100%;
            font-size: var(--text-size-xs);
            font-weight: 400;
        }
    }

    div.socialMedias {
        margin-top: 38px;

        a {
            &:not(:last-child) {
                margin-right: 16px;
            }
        }
    }

    div.footerLogo {
        margin-top: 24px;

        p,
        span {
            margin-top: 16px;
            color: var(--color-gray-400);
            font-size: var(--text-size-3xs);

            span {
                margin-left: 24px;
            }
        }
    }

    @media screen and (min-width: 570px) {
        div.linksContainer {
            display: flex;
            gap: 8px;
            align-items: stretch;
            justify-content: space-between;

            div.footerLinksContainer {
                margin-top: 0;
            }
        }
    }

    @media screen and (min-width: 1105px) {
        padding: 76.5px 0;
        margin: 0 auto;

        div.newsletter {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px;
            gap: 14px;
            min-width: 550px;
            

            input {
                &:first-child {
                    max-width: 140px;
                }
            }

            button {
                &:hover {
                    filter: brightness(1.1);
                }
            }
        }
    }
`