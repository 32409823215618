import styled from "styled-components";
import baixarBannerDesktop from "../../assets/images/baixar-banner-desktop.png";
import baixarBannerMobile from "../../assets/images/baixar-banner-mobile.png";

export const SectionTitle = styled.div`
    height: 128vw;
    max-height: calc(100vh - 165px);
    margin-top: 85px;
    background-image: url(${baixarBannerMobile});
    background-position: center;
    background-repeat: no-repeat;
    background-color: var(--color-purple-400);
    background-size: cover;
    
    position: relative;

    .space {
        display: none;
    }

    div#banner-text {
        max-width: 1488px;
        margin: 0 auto;
        margin-top: 40px;
        padding: 0 24px;

        h2 {
            text-align: center;
            color: var(--color-gray-600);
            font-size: clamp(var(--text-size-sm), calc(9vw - var(--text-size-sm)) ,var(--text-size-xlg));
            font-weight: 600;
            padding: 0 0px clamp(24px, 5vw, 40px);
        }

        div#banner-sub-text {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
            margin-left: 20px;
            flex-direction: column;
             
            svg {
                width: 25px;
                height: 34px;
            }
            p {
                font-size: var(--text-size-sm);
                color: var(--color-gray-600);
                margin-top: unset;
            }
        }
    }
    

    > div {
        width: 100%;
        margin: 0 auto;
        position: absolute;
        left: 50%;
        translate: -50%;
        /* padding-bottom: 100px; */
    }
    
    .buttons {
        display: none;
    }

    @media screen and (min-width: 1105px) {
        max-height: 814px;
        height: 90vh;
        max-width: none;
        background-image: url(${baixarBannerDesktop});
        background-color: var(--color-white);
        background-size: contain;

        .space {
            display: inline;
        }

        > div {
            position: relative;
            top: 0;
            left: 0;
            translate: 0;
            }

        div#banner-text {
            padding-top: clamp(190px, calc(31vh - 10px) ,300px);
            display: flex;
            justify-content: start;
            flex-direction: column;

            h2 {
                text-align: left;
                font-size: 38px;
                line-height: 44px;
                font-weight: 600;
                max-width: 581px;
                padding-left: 0;
                padding-right: 0;
            }

            p {
                font-size: var(--text-size-md);
                margin-top: 32px;
            }

             div#banner-sub-text {
                svg {
                    width: unset;
                    height: unset;
                }
                flex-direction: row;
                gap: 20px;

                p {
                    font-size: var(--text-size-md);
                }
            }
        }

    }
    @media screen and (min-width: 1105px) and (max-width: 1450px) {
        div#banner-text {
             h2 {
                font-size: 36px;
                max-width: 550px;
            }
            /*
            .space {
                display: none;
            } */
        }
}
`

export const SectionInfo = styled.div`
    background: var(--color-white);

    #platform {
        scroll-margin-top: 85px;
    }

    div.withLineBrake {
        h3 {
            text-wrap: balance;
            width: 85px;
        }
    }

    div.text-so {
        background-color: var(--color-gray-200);
        margin: auto;
        padding: 0 24px;
        text-align: center;

        div.container-so {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            gap: 20px;
            padding-bottom: 30px;

            svg.icon-face {
                width: 150px;
                height: 150px;
            }

            h3 {
                width: 200px;
            }

            .so {
                svg {
                    margin: 0px 10px;
                    width: 32px;
                }
            }
                
        }

        h3{
            font-size: var(--text-size-md);
            line-height: 28px;
            font-weight: 700;
        }

        p {
            font-size: var(--text-size-2xs);
            color: var(--color-gray-600);
            margin-top: 5px;
        }
    }

    div.cards-text {
        margin: auto;
        padding: 0 24px;
        text-align: center;
        margin-top 30px;

        h3,
        h4 {
            font-size: var(--text-size-md);
            line-height: 28px;
            font-weight: 700;
        }

        p {
            font-size: var(--text-size-2xs);
            color: var(--color-gray-600);
            margin-top: 16px;
            margin-bottom: 40px;
        }

        .cards {
            display: flex;
            gap: 40px;
            flex-direction: column;
            align-items: center;
            margin-bottom: 50px;

            .card {
                background-color: var(--color-gray-100);
                border-radius: 10px;
                padding: 35px;
                max-width: 500px
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                text-align: start;
                gap: 15px;
                display: flex;
                flex-direction: column;

                ul {
                    list-style: none;
                    margin-bottom: 20px;
                    text-align: left;
                    padding-left: 0;
                }

                ul li {
                    font-size: 14px;
                    margin-bottom: 8px;
                    line-height: 1.5;
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    svg {
                        min-width: 24px;
                    }
                }
    
                button {
                    background-color: #333;
                    color: white;
                    border: none;
                    border-radius: 40px;
                    padding: 10px 20px;
                    cursor: pointer;
                    width: 100%;
                    height: 64px;
                    font-size: var(--text-size-xsm);
                }
            }
        }
    }

    @media screen and (min-width: 1105px) {
        div.text-so {
            div.container-so {
                max-width: 1488px;
                margin: 0px auto;
                text-align: start;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                gap: unset;
                padding-bottom: unset;

                svg.icon-face {
                    width: 400px;
                    height: 400px;
                }

                div.so {
                    display:flex;
                    gap: 20px;

                    h3 {
                        font-size: var(--text-size-xlg);
                        line-height: 52px;
                        font-weight: 700;
                        width: 700px;

                        @media screen and (min-width: 1105px) {
                            max-width: 700px;    
                        }
                    }
                }

                p {
                    max-width: 650px;
                    font-size: var(--text-size-md);
                    margin-top: 32px;
                    width: 754px;
                    margin-top: 32px;
                }
            }
           
        }

        div.cards-text {
            margin: 100px auto;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;

            h4 {
                font-size: var(--text-size-xlg);
                line-height: 52px;
                font-weight: 700;
                width: 900px;
                @media screen and (min-width: 1105px) {
                    max-width: 900px;    
                }
            }

            p {
                max-width: 910px;
                font-size: var(--text-size-md);
                width: 910px;
                margin: auto;
                margin-top: 25px;
                margin-bottom: 50px;
            }

            .cards {
                flex-direction: row;
                margin-bottom: unset;
            }
        }
    }
`