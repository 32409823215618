import {
  SectionInfo,
  SectionTitle,
} from "./styles";
import { addCTAEvent } from "../../utils/dataLayer";
import { IconAndroid } from "../../assets/icons/IconAndroid";
import { IconApple } from "../../assets/icons/IconApple";
import { IconScrollBlack } from "../../assets/icons/IconScrollBlack";
import { IconFace } from "../../assets/icons/IconFace";
import { IconLink } from "../../assets/icons/IconLink";
import { IconMoreSquare } from "../../assets/icons/IconMoreSquare";
import { IconDownload2 } from "../../assets/icons/IconDownload2";
import { IconUpload } from "../../assets/icons/IconUpload";
import { IconAddSquare } from "../../assets/icons/IconAddSquare";

export const Baixar = () => {
  function handleCTAButtonClick() {
    addCTAEvent();
    window.open("http://www.app.zedasorte.com.br");
  }
  return (
    <>
      <SectionTitle>
        <div>
          <div id="banner-text">
            {/* &nbsp; é o caractere de um espaço em branco */ }
            <h2>
              _tenha uma experiência <span className="space">&nbsp;&nbsp;</span>
              única, baixe nosso app!
            </h2>
            <div id="banner-sub-text">
              <IconScrollBlack /><p>siga as instruções abaixo</p>
            </div>
          </div>
        </div>
      </SectionTitle>

      <SectionInfo id="info">
        <div className="text-so">
          <div className="container-so">
            <div>
              <div className="so">
                <h3>nosso app está disponível em:</h3>
                <IconAndroid />
                <IconApple width={ 56 } height={ 56 } />
              </div>
              <p>
                *apenas para jogadores com 18+
              </p>
            </div>
            <IconFace className='icon-face' />
          </div>
        </div>
        <div className="cards-text" id="platform">
          <h4>como fazer a instalação do nosso app</h4>
          <p>
            basta seguir nosso guia passo-a-passo. assim que você terminar a instalação,
            já poderá jogar com o app do zé da sorte
          </p>

          <div className="cards">
            <div class="card">
              <IconAndroid />
              <h2>como baixar no android</h2>
              <ul>
                <li>
                  <IconLink />
                  acesse: <a href="https://app.zedasorte.com.br">app.zedasorte.com.br</a>
                </li>
                <li>
                  <IconMoreSquare />
                  clique no ícone localizado na barra superior do seu celular
                </li>
                <li>
                  <IconDownload2 />
                  em seguida, clique na opção “instalar aplicativo”
                </li>
              </ul>
              <button onClick={handleCTAButtonClick}>instalar agora</button>
            </div>
            <div class="card">
              <IconApple width={ 56 } height={ 56 } />
              <h2>como baixar no iPhone</h2>
              <ul>
                <li>
                  <IconLink />
                  acesse: <a href="https://app.zedasorte.com.br">app.zedasorte.com.br</a>
                </li>
                <li>
                  <IconUpload />
                  clique no ícone localizado na barra inferior do seu celular
                </li>
                <li>
                  <IconAddSquare />
                  em seguida, clique na opção “adicionar à tela de início”
                </li>
              </ul>
              <button onClick={handleCTAButtonClick}>ir para o app</button>
            </div>
          </div>
        </div>
      </SectionInfo>
    </>
  );
};
