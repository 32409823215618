import styled from "styled-components";

export const GrafismImage = styled.div`
    display: flex;
    margin-bottom: -5px;

    picture, img {
        width: 100%;
    }     
`

// p.infoCardTitle {
//     text-align: center;
//     font-size: var(--text-size-2xs);
// }
// @media screen and (min-width: 1105px) {
//     p.infoCardTitle {
//         font-size: var(--text-size-md);
//     }
// }