import { useState } from "react";
import { Link } from "react-router-dom";
import { IconCloseBlack } from "../../assets/icons/IconCloseBlack";
import { IconMenu } from "../../assets/icons/IconMenu";
import { LogoNameGray } from "../../assets/icons/LogoNameGray";
import { scrollToElement } from "../../utils/scrollToElement";
import { LpVideo } from "../LpVideo";
import { Container, TransparentButton, BlackButton } from "./styles";
import { addCTAEvent, addInteraction } from "../../utils/dataLayer";
import { contentsLink } from "../LpFooter";

export const LpHeader = ({ setViewMenu, iconShowMenu }) => {
  const [showVideo, setShowVideo] = useState(false);

  function goToTop() {
    if (window.location.pathname === "/") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  function handleClick(name) {
    // console.log(window.location.pathname);
    const elements = {
      como_jogar: () => scrollToElement("#info"),
      conhecer_a_plataforma: () => scrollToElement("#platform"),
      perguntas: () => scrollToElement("#faq"),
    };
    try {
      if (window.location.pathname !== "/") {
        setTimeout(() => elements[name](), 500);
      } else {
        elements[name]();
      }
    } catch { }
    addInteraction(`lp:header-links:${name}`);
  }

  function handleLoginButtonClick() {
    addCTAEvent();
    window.open("https://app.zedasorte.com.br/login");
  }
  function handleRegisterButtonClick() {
    addCTAEvent();
    window.open("https://app.zedasorte.com.br/registrar");
  }

  return (
    <>
      {showVideo && <LpVideo setView={setShowVideo} />}
      <Container>
        <div className="itensContainer">
          <Link to="/" onClick={goToTop}>
            <LogoNameGray />
          </Link>
          <div className="linksContainer">
            <div className="links">
              <Link to="./quem-somos" onClick={() => addInteraction(`lp:header-links:quem_somos`)}>
                <span>quem somos</span>
              </Link>
              <Link to="/" onClick={() => handleClick("como_jogar")}>
                <span>como jogar</span>
              </Link>
              <Link to="/" onClick={() => handleClick("conhecer_a_plataforma")}>
                <span>conhecer a plataforma</span>
              </Link>
              <Link to={contentsLink}>
                <span>dicas do zé</span>
              </Link>
              <Link to={"/produtos/"}>
                <span>produtos</span>
              </Link>
              <Link to="./perguntas-frequentes">
                <span>perguntas frequentes</span>
              </Link>
              <Link to={"/baixar"}>
                <span>baixar</span>
              </Link>
            </div>
            <div className="buttons">
              {/* <BlackButton>Baixar App</BlackButton> */}
              <BlackButton onClick={handleLoginButtonClick}>acessar conta</BlackButton>
              <TransparentButton onClick={handleRegisterButtonClick}>criar conta</TransparentButton>
            </div>
          </div>
          <button className="menu" onClick={setViewMenu}>
            {iconShowMenu ? <IconCloseBlack /> : <IconMenu />}
          </button>
        </div>
      </Container>
    </>
  );
};
