export const pagesText = {
  about: {
    title: "Quem somos",
    content: (
      <>
        <h1>Quem somos?</h1>
        <p>
          O Zé da Sorte é uma startup que tem como propósito facilitar a vida de quem quer apostar
          nas loterias da caixa e têm dificuldade em ir até uma lotérica. Nosso maior objetivo é
          proporcionar comodidade, permitindo que você faça seu jogo online, de maneira rápida e
          muito segura. Estamos localizados em Curitiba, no Paraná, cidade conhecida como o maior
          centro tecnológico de startups inovadoras do mercado.
        </p>
        <p>
          Começamos a estruturar nossa operação no início de 2022, com um time multidisciplinar
          formado por profissionais reconhecidos no mercado, que atuam nos segmentos financeiro e
          tecnológico. Temos o propósito em comum de facilitar a vida das pessoas apostadoras e,
          claro, oferecer uma experiência incrível com nossos produtos.
        </p>
        <p>
          Com o site do Zé da Sorte você pode jogar a qualquer momento, pois os serviços estão
          disponíveis 24 horas por dia, 7 dias da semana. E o melhor: não tem fila! Você joga no
          conforto do seu lar, com segurança e sem pressa! E tem mais: Você paga com pix, cartão de
          crédito ou débito e assim que seu jogo é registrado na lotérica parceira, seu comprovante
          de pagamento é liberado para acesso.
        </p>
        <p>
          Além de tudo isso, se você ficar com dúvida na hora de escolher os números, a gente ajuda
          você com a opção do palpite do zé disponível na plataforma. São palpites certeiros que
          aumentam suas chances de ganhar. Assim, você joga mais rápido e não perde aquela aposta
          que tanto queria.
        </p>
        <p>
          E sabe porque é rápido? Porque a nossa experiência é fluida, é inteligente, é feita
          pensada para você pessoa que joga e precisa de facilidade para fazer aquela fezinha. Além
          de rápidos, somos comprometidos com o seu jogo. Todas as apostas são registradas em
          lotéricas e utilizamos a plataforma do mercado pago para que todos os seus dados estejam
          seguros e nada seja utilizado sem que você queira.
        </p>
        <p>Somos seguros, eficientes e transparentes. Somos o Zé da [sua] Sorte!</p>
      </>
    ),
  },
  privacy: {
    title: "Privacidade",
    content: (
      <>
        <h1>Política de Privacidade</h1>
        <h4>
          Você pode desabilitar os cookies na configuração do seu navegador, mas isso pode afetar a
          sua experiências de navegação.
        </h4>
        <p>
          Ao cadastrar-se ou navegar pelo Site, os visitantes e Usuários aceitam a presente Política
          de Cookies e Política de Privacidade.
        </p>
        <p>A Política de Cookies e a Política de Privacidade integram os Termos de Uso do Site.</p>
        <p>
          Zé da Sorte tem por objetivo, por meio desta Política de Cookies, informar aos visitantes
          e Usuários sobre a utilização da tecnologia de cookies para fins de monitoramento e
          melhorias em seu Site.
        </p>
        <p>
          Cookies são pequenos fragmentos de informação que se instalam no computador ou dispositivo
          semelhante utilizado para acessar a internet, com duração limitada de tempo, e que ajudam
          a armazenar e, por vezes, rastrear informações sobre o uso do Site.
        </p>
        <p>O Site utiliza os cookies principalmente para:</p>
        <p>
          (i) Monitorar os interesses, o comportamento e a demografia dos visitantes e Usuários do
          Site;
        </p>
        <p>
          (ii) Analisar as páginas visitadas e buscas realizadas pelos visitantes e Usuários, de
          forma a melhorar as iniciativas comerciais e promocionais do Site;
        </p>
        <p>(iii) Medir a efetividade das promoções; e</p>
        <p>
          (iv) Coletar informações sobre o número de visitantes e Usuários que visitaram o Site.
        </p>
        <p>
          Podem ser encontrados cookies ou outros sistemas similares instalados por terceiros nas
          páginas do Site. A presente política, no entanto, refere-se apenas à utilização de cookies
          pelo Zé da Sorte ou terceiros que prestem serviços em seu nome. Cookies e outras
          tecnologias utilizadas por terceiros anunciantes e parceiros são de inteira e exclusiva
          responsabilidade dos respectivos.
        </p>
        <p>
          A maioria dos navegadores de internet aceita cookies automaticamente, mas os visitantes e
          Usuários podem configurar o seu navegador para bloquear os cookies ou para removê-los. No
          entanto, o bloqueio ou remoção dos cookies utilizados no Site não permitirá que os
          visitantes e Usuários tirem todo o proveito deste.
        </p>
        <p>
          Para bloqueio ou remoção dos cookies, siga os procedimentos indicados pelos fabricantes de
          cada navegador, como:
        </p>
        <p>
          Google Chrome:{" "}
          <a
            rel="noreferrer"
            href="https://support.google.com/chrome/answer/95647?hl=pt-BR"
            target="_blank"
          >
            (https://support.google.com/chrome/answer/95647?hl=pt-BR)
          </a>
        </p>
        <p>
          Internet Explorer:{" "}
          <a
            rel="noreferrer"
            href="http://windows.microsoft.com/pt-br/internet-explorer/delete-manage-cookies#ie=ie-11"
            target="_blank"
          >
            (http://windows.microsoft.com/pt-br/internet-explorer/delete-manage-cookies#ie=ie-11
          </a>
        </p>
      </>
    ),
  },
  terms: {
    title: "Termos de uso",
    content: (
      <>
        <h1>Termos e condições de uso</h1>
        <h4>1. Informações Iniciais</h4>
        <p>
          1.1. Você está conectado a um site (doravante denominado "o site") ou aplicativo (“o App”)
          publicado pelo Zé da Sorte Serviços de Intermediação Ltda. O uso deste site ou App está
          sujeito aos termos e condições gerais estabelecidos abaixo e a todas as leis e
          regulamentos aplicáveis. Ao acessar e usar o site ou o App, você aceita expressamente os
          termos e condições gerais aqui estabelecidos.
        </p>
        <p>
          1.2. As condições estabelecidas neste documento (“Termos e condições de Uso”) disciplinam
          os serviços prestados por Zé da Sorte Serviços de Intermediação Ltda. (“Zé da Sorte”),
          pessoa jurídica de direito privado, com sede na cidade de Curitiba-PR, na Av. Vereador
          Toaldo Túlio, 2072, CEP 82.320-010, inscrito no CNPJ 45.824.777/0001-09, ao seu cliente
          (“Usuário”), através deste site ou App.
        </p>
        <h4>2. Serviços</h4>
        <p>
          2.1. Os serviços prestados e ofertados pelo Zé da Sorte são a realização, a pedido e em
          nome do Usuário, de apostas em jogos lotéricos oficiais.
        </p>
        <p>
          2.1.2. Considerando o objeto da prestação de serviços acima mencionado, o Usuário no ato
          da aceitação destes Termos e condições de Uso nomeia e constitui como seu procurador para
          finalidade específica de realização de apostas e seus desdobramentos, o Zé da Sorte, nos
          termos dos Artigos 653 e seguintes do Código Civil.
        </p>
        <p>
          2.1.3. O Zé da Sorte se compromete a envidar os melhores esforços para efetivar as apostas
          perante casas lotéricas credenciadas pela Caixa Econômica Federal, nos termos solicitados
          pelo Usuário. O Zé da Sorte não possui nenhum vínculo direto com a Caixa Econômica Federal
          e atua como representante do Usuário na realização das apostas.{" "}
        </p>
        <p>
          2.2. O Usuário concorrerá a aposta, apenas e tão somente, após a confirmação do Zé da
          Sorte de que houve efetivação dos serviços perante uma casa lotérica credenciada.
        </p>
        <p>
          2.3. O Zé da Sorte envidará os melhores esforços para realização da aposta solicitada pelo
          Usuário desde que ela seja efetivada no site ou App até as 16:00 horas do dia do concurso.
        </p>
        <p>
          2.3.1. Caso por alguma razão a aposta não se efetivar no dia da solicitação pelo Usuário
          esta será realizada no dia útil subsequente.
        </p>
        <p>
          2.4. Após efetivada a aposta junto à Casa Lotérica oficial, o Zé da Sorte se
          responsabilizará pela guarda e custódia dos respectivos bilhetes.
        </p>
        <p>
          2.5. O Zé da Sorte poderá possibilitar ao Usuário a solicitação de efetivação de apostas
          em qualquer modalidade de jogo lotérico oficial que venha a ser lançada pela Caixa
          Econômica Federal.
        </p>
        <p>
          2.6. Em caso de premiação, o Zé da Sorte comunicará o Usuário através dos dados constantes
          no cadastro do site ou App sobre a contemplação e realizará os procedimentos para resgate
          do prêmio, após providenciará o depósito dos valores para o Usuário através de depósito
          bancário em conta de titularidade do Usuário.
        </p>
        <p>
          2.7. Os prêmios serão recebidos pelos prepostos do Zé da Sorte junto à Caixa Econômica
          Federal, quando aplicável.
        </p>
        <h4>3. Condição para contratação dos Serviços</h4>
        <p>
          3.1. Para contratar os Serviços do Zé da Sorte, o Usuário deverá ser pessoa física, maior
          (acima de 18 anos) e juridicamente capaz.
        </p>
        <p>
          3.2. O Usuário garante e se responsabiliza pelos dados constantes do cadastro por ele
          preenchido, comprometendo-se a mantê-lo devidamente atualizado.
        </p>
        <h4>4. Da remuneração dos Serviços Prestados</h4>
        <p>
          4.1. Pela prestação dos serviços oferecidos ao Usuário, o Zé da Sorte, na qualidade de
          mandatário, perceberá uma remuneração fixa que consistirá na diferença entre o valor total
          pago pelo Usuário e o preço estipulado pela Caixa Econômica Federal para a efetivação da
          respectiva aposta.
        </p>
        <p>
          4.1.1. O valor objeto da cobrança dos serviços constará em destaque no momento da
          realização do pedido de aposta no site ou App.
        </p>
        <p>
          4.2. Adicionalmente, o Usuário arcará com despesas do Zé da Sorte para realização das
          transferências bancárias, impostos ou outras porventura devidas em decorrência da retirada
          e / ou do pagamento de prêmio ao Usuário.
        </p>
        <p>
          4.3. Em caso de premiação superior a R$ 50.000,00 (Cinquenta mil reais), será devido ao Zé
          da Sorte um percentual de 0,5% da premiação auferida a título de prestação de serviços
          complementares.
        </p>
        <h4>5. Uso Adequado e Responsabilidades</h4>
        <p>
          5.1. Ao utilizar os Serviços, o Usuário se compromete a respeitar a legislação brasileira
          vigente e o disposto nestes Termos e Condições de Uso, não devendo produzir,
          disponibilizar ou compartilhar qualquer conteúdo que: a) Implique a prática de ato ilícito
          e viole a legislação brasileira vigente; b) Viole direitos de terceiro ou direitos do Zé
          da Sorte; c) Seja falso, incorreto, impreciso, extemporâneo e que possa induzir outrem a
          erro; d) Disponibilize ou permita o acesso a conteúdo ilegal, violento, pornográfico ou
          qualquer outro ato contrário à lei e à ordem pública; e) Induza a discriminação ou incite
          o ódio contra pessoas e/ou grupos de pessoas em razão de nacionalidade, raça, religião,
          orientação sexual, gênero, condição física, nacionalidade, dentre outros atos que
          contrariem a ordem pública e a legislação brasileira vigente; f) Seja resguardado por
          direito de propriedade intelectual de terceiro e você não tenha autorização prévia para
          utilizá-lo; g) Contenha vírus ou outro elemento que seja capaz, em razão de suas
          características (como forma, extensão, etc), de causar danos ou impedir o funcionamento
          regular da rede do sistema e/ou de equipamentos informáticos ("hardware" e "software") do
          Zé da Sorte ou de terceiros.
        </p>
        <p>
          5.2. Ao acessar e usar o site ou App, o Usuário se responsabilizará: a) Por todo ato, por
          ação ou omissão, realizado em nossos sites institucionais, responsabilizando-se pela
          prática de todo e qualquer ato ilícito cometido pelo Usuário; b) Pelo conteúdo gerado pelo
          Usuário; c) Pela reparação de danos causados a terceiros ou ao Zé da Sorte, a partir do
          uso dos Serviços; d) Pelo seu próprio acesso à internet e ao pagamento pelos serviços de
          acesso à rede; e) Pelo equipamento necessário para realizar a conexão à internet, como
          computador e modem, garantindo a segurança adequada do ambiente, mediante a utilização de
          ferramentas como antivírus e firewall.
        </p>
        <h4>6. Direitos Autorais</h4>
        <p>
          6.1. Este site ou App é totalmente protegido por direitos de propriedade intelectual,
          tanto no que se refere à forma (escolhas, plano, layout da página, acesso aos dados e sua
          organização, etc.) quanto ao conteúdo (textos, fotografias, desenhos, slogans, sons ou
          imagens, documentos para download e, de maneira mais geral, quaisquer trabalhos incluídos
          no site ou App). Todo o conteúdo apresentado nas páginas deste site ou App é de
          propriedade exclusiva do Zé da Sorte ou de terceiros que deram autorização para uso.
        </p>
        <p>
          6.2. É proibido reproduzir, representar, usar ou distribuir, o conteúdo do Site ou App, em
          parte ou na sua totalidade, por qualquer meio ou para vender, revender, retransmitir ou
          fazer disponível a terceiros. O não cumprimento será considerado uma violação dos direitos
          autorais, sendo o autor dessa violação responsabilizado civil e criminalmente por tais
          atos.
        </p>
        <h4>7. Disposições Gerais</h4>
        <p>
          7.1. O usuário também tem importante participação na proteção de sua privacidade.
          Recomendamos expressamente que o Usuário não divulgue sua senha para ninguém, seja por
          telefone, por e-mail ou qualquer outro meio. O Zé da Sorte não se responsabiliza pelos
          danos causados pelo descumprimento de tais recomendações.
        </p>
        <p>
          7.2. O Usuário reconhece que leu estes termos e condições gerais de uso e concorda em
          ficar vinculado a eles.
        </p>
        <p>
          7.3. Sob nenhuma circunstância o Zé da Sorte pode ser responsabilizado por qualquer dano
          ou perda direta ou indireta, incluindo, entre outros, danos materiais, perda de dados ou
          programas, perda financeira resultante do acesso, conexão ou uso deste site ou de sites
          relacionados a este.
        </p>
        <p>
          7.4. O Zé da Sorte não pode ser responsabilizado por eventos fora de seu controle ou por
          qualquer dano que possa ser sofrido pelo seu ambiente técnico, como, por exemplo,
          hardware, software, sistemas de rede e, em geral, qualquer equipamento usado para acessar
          o site ou o App.
        </p>
        <p>
          7.5. O Zé da Sorte poderá bloquear o acesso total ou parcial, preventivo ou corretivo,
          temporário ou definitivo, do Usuário em caso de suspeita ou ocorrência de operações não
          conformes, que possam comprometer a segurança e sigilo das informações e operações, ou
          ainda, em caso de violação de procedimentos estabelecidos neste Termo ou em decorrência de
          normas e regulamentos aplicáveis.
        </p>
        <p>
          7.6. No caso de uma disputa entre Usuário e seu operador de telecomunicações, ou qualquer
          outro operador ou intermediário, ou no caso de uma falha ou falha no sistema do computador
          ou nos seus meios de acesso, ou na Internet e no seu acesso a esta rede, qualquer que seja
          a causa ou origem, o Zé da Sorte não pode ser responsabilizado se, como resultado, o
          Usuário tiver dificuldades em transmitir e encaminhar suas solicitações dentro de um prazo
          normal.
        </p>
        <p>
          7.7. A utilização do cadastro do Usuário por outra pessoa, sem a ciência e/ou anuência do
          Usuário, é vedada em qualquer situação. Caso seja identificado o acesso por terceiros, com
          a ciência e/ou anuência do Usuário, o Zé da Sorte isenta-se de quaisquer responsabilidades
          decorrentes do uso indevido e da solicitação de serviços registrados em seu cadastro,
          sendo tal conduta de exclusiva e inteira responsabilidade do Usuário.
        </p>
        <p>
          7.8. A utilização indevida do cadastro por terceiros, com a ciência e/ou anuência do
          Usuário, pode constituir crime de falsa identidade, na forma do artigo 307 do Código
          Penal, a ser apurado perante os competentes órgãos administrativos e judiciais, que
          poderão, em certos casos, ter acesso a todos os documentos e dados relativos ao cadastro
          utilizado indevidamente.
        </p>
        <p>
          7.9. Em virtude das regras nacionais e internacionais de prevenção à lavagem de dinheiro e
          ao financiamento de atividades criminosas, o Zé da Sorte poderá compartilhar
          voluntariamente com órgãos governamentais informações de qualquer forma relacionadas com o
          cadastramento, as operações e eventuais contemplações de seus Usuários. Poderá, também,
          encaminhar quaisquer informações que sejam de seu conhecimento, e que venham a ser
          solicitadas por órgãos governamentais, independentemente de notificação ou aviso ao
          Usuário.
        </p>
        <p>
          7.10. O Zé da Sorte e seus parceiros envidam todos os esforços para garantir a precisão
          das informações fornecidas pelo site ou App e se reserva o direito de corrigir o conteúdo
          a qualquer momento, sem aviso prévio. Apesar dos critérios de segurança implementados pelo
          Zé da Sorte, este não pode garantir que as informações estejam completas ou que não foram
          modificadas por terceiros (intrusão, vírus).
        </p>
        <p>
          7.11. O Zé da Sorte poderá, ao seu exclusivo critério, a qualquer tempo, e sem a
          necessidade de comunicação prévia ao Usuário: (i) Encerrar, modificar ou suspender, total
          ou parcialmente, o acesso do Usuário ao site ou App, quando referido acesso ou cadastro
          estiver em violação das condições estabelecidas nestes Termos de Uso; (ii) Excluir, total
          ou parcialmente, as informações cadastradas pelo Usuário que não estejam em consonância
          com as disposições destes Termos de Uso; (iii) A qualquer tempo, o Zé da Sorte poderá
          enviar ao Usuário mensagens de e-mail ou outras correspondências de caráter informativo,
          comercial e / ou promocional, salvo expressa solicitação em contrário.
        </p>
        <h4>8. Responsabilidade pelo Acesso</h4>
        <p>
          8.1. A senha utilizada pelo Usuário é de caráter pessoal e intransferível, sendo de
          responsabilidade do próprio Usuário não repassá-la a terceiros, para sua própria
          segurança. A escolha da senha indicada no momento da efetivação do cadastro é livre e
          incondicionada, sendo de exclusiva responsabilidade do Usuário.
        </p>
        <p>
          8.2. O Zé da Sorte não terá acesso à senha pessoal do Usuário, pois ela é criptografada.
        </p>
        <p>
          8.3. O Usuário compromete-se a informar imediatamente ao Zé da Sorte a perda, o roubo ou o
          extravio da senha, para que sejam então tomadas as devidas providências.
        </p>
        <h4>9. Responsabilidade e Indenização</h4>
        <p>
          9.1. O Zé da Sorte não responderá, em nenhuma hipótese, nem mesmo em caráter solidário ou
          subsidiário:
        </p>
        <p>
          a) Por premiações que não estejam vinculadas a apostas efetivadas, ou seja, que não
          estejam confirmadas no site ou App e com o status devidamente atualizado;
        </p>
        <p>
          b) Por eventuais prejuízos sofridos pelo Usuário em razão de falhas no sistema de
          informática, de telefonia ou nos servidores que independam de culpa do Zé da Sorte, ou em
          sua conectividade com a internet de modo geral;
        </p>
        <p>c) Por eventuais prejuízos sofridos em virtude de fraudes praticadas por terceiros.</p>
        <p>
          9.2. Em nenhum caso o Zé da Sorte será responsável por danos pessoais ou qualquer prejuízo
          incidental, especial, indireto ou consequente, lucros cessantes, incluindo, sem limitação,
          prejuízos por perda de lucro, corrupção ou perda de dados, falha de transmissão ou
          recepção de dados, perda de chance, não continuidade do negócio ou qualquer outro prejuízo
          ou perda comercial, decorrentes ou relacionados ao seu uso ou sua inabilidade em usar o
          site ou App, por qualquer outro motivo.
        </p>
        <p>
          9.3. Na eventualidade do Zé da Sorte ser compelido, por decisão judicial transitada em
          julgado, a indenizar ou ressarcir o Usuário por danos sofridos, apesar das previsões
          estabelecidas neste instrumento, o valor devido ao Usuário estará limitado a 25% (vinte e
          cinco por cento) da totalidade das quantias efetivamente pagas pelo Usuário ao Zé da Sorte
          a título de Prestação de Serviços.
        </p>
        <h4>10. Disposições Finais, Lei Aplicável e Foro</h4>
        <p>
          10.1. Estes Termos de Uso vigerão por prazo indeterminado, a partir do aceite do Usuário,
          podendo ser modificados ou rescindidos unilateralmente pelo Zé da Sorte a qualquer tempo,
          sem qualquer ônus, mediante simples comunicação por qualquer meio ao Usuário.
        </p>
        <p>
          10.2. A declaração de nulidade ou inaplicabilidade de que qualquer item, termo ou
          disposição ora previsto não implicará a nulidade ou inaplicabilidade de quaisquer outros
          itens, termos ou disposições aqui contidos, os quais permanecerão em pleno vigor e cujos
          efeitos não serão atingidos.
        </p>
        <p>
          10.3. Toda e qualquer controvérsia oriunda dos termos expostos neste documento serão
          solucionados de acordo com a lei brasileira, sendo competente o foro da cidade de
          Curitiba, Estado do Paraná, com exclusão de qualquer outro por mais privilegiado que seja.
        </p>
      </>
    ),
  },
  cookies: {
    title: "Cookies",
    content: (
      <>
        <h1>Política de Cookies</h1>
        <h4>Introdução</h4>
        <p>
          O Zé da Sorte está comprometido em proteger sua privacidade e mantê-lo informado sobre
          como usamos seus dados. Esta Política de Cookies detalha como coletamos estas informações
          e usamos para fornecer um serviço personalizado para você.
        </p>
        <h4>Quem somos e compromisso</h4>
        <p>
          Zé da Sorte Serviços de Intermediação Ltda, pessoa jurídica de direito privado, com sede
          na cidade de Curitiba-PR, inscrito no CNPJ 45.824.777/0001-09, doravante denominado “Zé da
          Sorte” está comprometido em manter a privacidade dos dados pessoais obtidos para prestação
          de seus serviços e cumprir a legislação aplicável ao tratamento de dados pessoais (“Dados
          Pessoais”), e se houver dados sensíveis (“Dados Sensíveis”). Isso inclui, mas não está
          limitado à Lei Geral de Proteção de Dados.
        </p>
        <h4>O que são Cookies</h4>
        <p>
          Cookies são pequenos arquivos, denominados rastreadores, armazenados no disco rígido do
          seu dispositivo final (computador, smartphone, tablet, console de videogame etc.) ao
          navegar por um site, utilizar um aplicativo, ler um e-mail, instalar e utilizar um
          software.
          <br />
          Os cookies podem enviar informações ao navegador do usuário e permitir que esse navegador
          envie informações ao site de origem (por exemplo: ID da sessão, escolha de um idioma e
          outras preferências no site).
        </p>
        <p>
          Através dos cookies não é possível identificá-lo pessoalmente, mas apenas coletar
          informações específicas sobre seu dispositivo, navegador, interações com a nossa
          plataforma e, em alguns casos, sua posição geográfica.
        </p>
        <p>
          As tecnologias utilizadas podem conectar vários dispositivos para determinar, com um grau
          de probabilidade suficiente, que os dispositivos pertencem ao mesmo usuário.
        </p>
        <p>
          Os cookies são transferidos do servidor do site para o seu navegador. Os cookies permitem
          que o Zé da Sorte identifique o dispositivo final no qual o cookie é salvo durante seu
          período de validade ou registro. Cada site pode acessar apenas os cookies salvos no seu
          dispositivo final. Os dados podem ser armazenados em cookies se você concordar ou se for
          necessário por razões técnicas, ou seja, para acesso seguro ao nosso site.
        </p>
        <h4>Tipos de Cookies Utilizados e suas Finalidades</h4>
        <ul>
          <li>
            Cookies essenciais - Esses cookies são essenciais para o bom funcionamento do site e de
            suas funções. Têm por finalidade permitir ou facilitar a comunicação eletrônica
            (detecção dos erros em links, identificação de links, segurança do site, etc) ou
            fornecer serviços de comunicação online quando você interage com o site (preferências de
            exibição, memorização das informações introduzidas em formulários, acesso à sua conta de
            usuário, etc.)
            <br />
            Estes cookies são obrigatórios para o bom funcionamento do site e, portanto, você não
            pode se opor à utilização deste tipo de cookies.
          </li>
          <li>
            Cookies de Marketing - Estes cookies servem para elaborar e direcionar ofertas
            publicitárias de acordo com os seus interesses e leva em conta o número de visitas que
            realizou em nossos sites e plataformas. Tal ação permite tornar mais personalizada e
            delimitar o número de vezes da exibição do anúncio. Além disso, estes cookies ajudam a
            medir a eficácia da publicidade.
            <br />A utilização e o armazenamento destes cookies estão condicionados à obtenção do
            seu consentimento.
          </li>
          <li>
            Cookies funcionais - Esses cookies coletam dados para lembrar as escolhas que os
            usuários fazem e para melhorar e proporcionar uma experiência mais personalizada.
            <br />A utilização e o armazenamento destes cookies estão condicionados à obtenção do
            seu consentimento.
          </li>
          <li>
            Cookies analíticos - Cookies de mediação de audiência têm por finalidade analisar a
            frequência de uso do nosso site, a fim de compreender melhor de que forma ele é
            utilizado, permitindo assim melhorar a sua experiência de navegação.
            <br />A utilização e o armazenamento destes cookies estão condicionados à obtenção do
            seu consentimento.
          </li>
        </ul>
        <h4>Configuração e personalização dos Cookies</h4>
        <p>
          Você pode desativar ou excluir os cookies, bem como demais tecnologias de coleta de dados,
          nas configurações do seu navegador e nas configurações do sistema operacional do seu
          dispositivo, com exceção dos cookies de funcionamento que, se desativados, não permitirão
          o uso dos Sites e Aplicativos.
          <br />
          Apenas lembramos que se determinados cookies forem desabilitados, os Sites ou Aplicativos
          ou alguns de seus recursos ou funcionalidades poderão não funcionar corretamente.
        </p>
        <h4>Responsável pelo Controle de Dados Pessoais</h4>
        <p>
          Os dados pessoais fornecidos para o Zé da Sorte, a depender do serviço ofertado terá o Zé
          da Sorte Serviços de Intermediação Ltda, pessoa jurídica de direito privado, com sede na
          cidade de Curitiba-PR, na Av. Vereador Toaldo Túlio, 2072, CEP 82.320-010, inscrito no
          CNPJ 45.824.777/0001-09, como Controladora ou Operadora. O Encarregado de Dados, Renato
          Negrão, pode ser contatado através do endereço de e-mail:{" "}
          <a href="mailto:contato@zedasorte.com.br">contato@zedasorte.com.br</a>.
        </p>
        <h4>Lei Aplicável e Resolução de Conflitos</h4>
        <p>
          Toda e qualquer controvérsia oriunda dos termos expostos na presente Política de Cookies
          serão solucionados de acordo com a lei brasileira, sendo competente o foro da cidade de
          Curitiba, Estado do Paraná, com exclusão de qualquer outro por mais privilegiado que seja.
        </p>
        <h4>Disposições Gerais e mudanças em nossa política de Cookies</h4>
        <p>
          Eventuais omissões ou meras tolerâncias das partes no exigir o estrito e pleno cumprimento
          desta Política de Cookies ou de prerrogativas decorrentes dela ou da lei, não constituirão
          novação ou renúncia, nem afetarão o exercício de quaisquer direitos aqui previstos, que
          poderão ser plena e integralmente exercidos, a qualquer tempo. Caso se perceba que uma
          disposição é nula, as disposições restantes desta Política de Privacidade permanecerão em
          pleno vigor e um termo válido substituirá o termo nulo, refletindo nossa intenção, tanto
          quanto possível.
        </p>
        <p>
          Todas as alterações que fizermos nesta Política de Cookies no futuro serão publicadas
          nesta página. Reservamo-nos o direito de fazer alterações nesta Política de Cookies. As
          alterações entrarão em vigor imediatamente após tal alteração ser notificada em nosso site
          por um aviso proeminente. Seu uso contínuo do nosso site ou aplicativo após tal
          notificação constitui a sua aceitação das alterações.
        </p>
      </>
    ),
  },
};
