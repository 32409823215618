import { Link } from "react-router-dom";
import { IconFacebookCircle } from "../../assets/icons/IconFacebookCircle";
import { IconInstagramCircle } from "../../assets/icons/IconInstagramCircle";
import { IconLinkedinCircle } from "../../assets/icons/IconLinkedinCircle";
import { Container, SectionFooter, Breadcrumbs } from "./styles";
import { scrollToElement } from "../../utils/scrollToElement";
import { IconNavbarHomeActive } from "../../assets/icons/IconNavbarHomeActive";
import { contentsText } from "../../utils/contentsText";

// esqueci que não era typescript, pode apagar se quiser
//
// interface ILastestContent {
//   title: string;
//   content: string;
//   link: string;
// }
export const contentsLink = "/dicas-do-ze/";

export const LpFooter = () => {
  const footerContent = contentsText.get(
    "teimosinha-na-mega-sena-a-estrategia-inteligente-para-aumentar-suas-chances-de-ganhar"
  );
  return (
    <Container>
      <div className="content">
        <SectionFooter>
          <Breadcrumbs>
            <IconNavbarHomeActive />
            <span>/</span>
            <Link to={contentsLink}>dicas do zé</Link>
            <span>/</span>
            <Link to={contentsLink + footerContent.link}>{footerContent.breadCrumb}</Link>
          </Breadcrumbs>

          <h1>{footerContent.title}</h1>
          <div className="line" />

          <p>{footerContent.description}</p>

          <Link to={contentsLink + footerContent.link}>conferir conteúdo</Link>
        </SectionFooter>
      </div>

      <div className="footerContainer">
        <SectionFooter>
          <h1>
            <a href="mailto:contato@zedasorte.com.br">contato@zedasorte.com.br</a>
            <div className="line" />
          </h1>
          <div className="linksContainer">
            <div className="footerLinksContainer">
              <p>zé da sorte</p>
              <Link to="./quem-somos">Quem somos</Link>
              <Link
                to="/"
                onClick={() => {
                  if (window.location.pathname !== "/") {
                    setTimeout(() => scrollToElement("#info"), 500);
                  } else {
                    scrollToElement("#info");
                  }
                }}
              >
                Como jogar
              </Link>
              <Link to={contentsLink}>Dicas do zé</Link>
            </div>
            <div className="footerLinksContainer">
              <p>Transparência</p>
              <Link to="./privacidade">Privacidade</Link>
              <Link to="./termos-de-uso">Termos de Uso</Link>
              <Link to="./cookies">Cookies</Link>
            </div>
            <div className="footerLinksContainer">
              <p>Ajuda</p>
              <a href="mailto:contato@zedasorte.com.br">contato@zedasorte.com.br</a>
              <Link to="./perguntas-frequentes">Dúvidas frequentes</Link>
            </div>
          </div>
          <div className="infoContainer">
            <div className="socialMedias">
              <a
                rel="noreferrer"
                href="https://www.facebook.com/oficialzedasorte/"
                target="_blank"
              >
                <IconFacebookCircle />
              </a>
              <a
                rel="noreferrer"
                href="https://www.instagram.com/ozedasorteoficial/"
                target="_blank"
              >
                <IconInstagramCircle />
              </a>
              <a
                rel="noreferrer"
                href="https://www.linkedin.com/company/zedasorte/"
                target="_blank"
              >
                <IconLinkedinCircle />
              </a>
            </div>
            <div className="footerLogo">
              <p>
                © {new Date().getFullYear()} Zé da Sorte <span>Todos os direitos reservados.</span>
              </p>
            </div>
          </div>
        </SectionFooter>
      </div>
    </Container>
  );
};
